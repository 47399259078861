import React from 'react';

const ResetLinkSend = () => {

    const handleResend = () => {
        console.log('Resend link');
    }

    return (
        <div className='flex-1 h-screen flex flex-col space-y-3 justify-center items-center text-center'>
            <h1 className='font-bold text-2xl mb-4'>Forgot Password?</h1>
            <h1>Password reset link is sent to your email address if this is registered with us.</h1>
            <div className="flex">
                <h1>Did not get the link?</h1>
                <h1 onClick={handleResend} className='text-indigo-600 hover:text-indigo-600'>&nbsp; SEND AGAIN</h1>
            </div>
        </div>
    );
}

export default ResetLinkSend;
