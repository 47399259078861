import React, {  } from 'react';
import {  useNavigate } from 'react-router-dom';

const ResetPass = () => {

    const navigate = useNavigate();
    const [newPassword, setNewPassword] = React.useState('');
    const [newPasswordConfirm, setNewPasswordConfirm] = React.useState('');

    const handleReset = (e) => {
        e.preventDefault();
        console.log(newPassword, newPasswordConfirm);
        navigate('/reset-done');
    }

    return (
        <div className="flex min-h-screen flex-1 flex-col justify-center px-4 sm:px-6 py-12 lg:px-8">
            <div className="shadow-lg mx-auto px-4 py-6 rounded sm:w-96 w-10/12">
                <div className="sm:mx-auto sm:w-full sm:max-w-sm">
                    <h2 className="mt-6 text-center text-2xl font-bold leading-4 tracking-tight text-gray-900">
                        Reset Your Password
                    </h2>
                </div>

                <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm px-4">
                    <div className="space-y-4">
                        <div>
                            <label htmlFor="password" className="block text-sm font-medium leading-4 text-gray-900">
                                New Password <span className='text-rose-400'>*</span>
                            </label>
                            <div className="mt-2">
                                <input
                                    onChange={(e) => setNewPassword(e.target.value)}
                                    type="password"
                                    autoComplete="current-password"
                                    required
                                    className='border-b w-full outline-none pb-1 px-2 focus:border-indigo-600'
                                />
                            </div>
                        </div>

                        <div>
                            <label htmlFor="password" className="block text-sm font-medium leading-6 text-gray-900">
                            Confirm New password <span className='text-rose-400'>*</span>
                            </label>
                            <div className="mt-2 mb-4">
                                <input
                                    onChange={(e) => setNewPasswordConfirm(e.target.value)}
                                    type="password"
                                    autoComplete="current-password"
                                    required
                                    className='border-b w-full outline-none pb-1 px-2 focus:border-indigo-600'
                                />
                            </div>
                        </div>

                        <button
                            onClick={handleReset}
                            className="w-full rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500"
                        >
                            Reset Password
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ResetPass;
