import React, { useState } from 'react';

const AddRole = ({ addRole }) => {

    const [checked, setChecked] = useState([]);
    const [roleName, setRoleName] = useState('');

    const handleCheckboxChange = (role) => {

        if (checked.includes(role)) {
            setChecked((prevChecked) => prevChecked.filter((item) => item !== role));
        } else {
            setChecked((prevChecked) => [...prevChecked, role]);
        }
    };

    const handleSave = () => {
        console.log(checked, roleName);
    }

    return (
        <div className='bg-modal'>
            <div className="modal w-80 sm:w-96">
                <p className='text-xl font-semibold leading-10'>Create New Role</p>
                <hr />
                <p>Role Name</p>
                <input onChange={(e) => setRoleName(e.target.value)} type="text" className='border w-full outline-none rounded px-2 bg-gray-100'/>
                <p>Permission</p>
                <div className="flex flex-wrap gap-x-4">
                    <div className=''>
                        <input type="checkbox" onChange={() => handleCheckboxChange('Employee/Resource')}/> Employee/Resource
                    </div>
                    <div className=''>
                        <input type="checkbox" onChange={() => handleCheckboxChange('Calendar')}/> Calendar
                    </div>
                    <div className=''>
                        <input type="checkbox" onChange={() => handleCheckboxChange('Jobs Management')}/> Jobs Management
                    </div>
                    <div className=''>
                        <input type="checkbox" onChange={() => handleCheckboxChange('Tasks Management')}/> Tasks Management
                    </div>
                    <div className=''>
                        <input type="checkbox" onChange={() => handleCheckboxChange('Leave Requests')}/> Leave Requests
                    </div>
                </div>
                <div className="flex gap-4 text-white pt-2">
                    <button onClick={handleSave} className='flex-1 bg-green-500 hover:bg-green-400 py-1 rounded'>Save</button>
                    <button onClick={addRole} className='flex-1 bg-rose-500 hover:bg-rose-400 py-1 rounded'>Cancel</button>
                </div>
            </div>
        </div>
    );
}

export default AddRole;
