import React, { useState, useRef, useEffect } from "react";
import { Html5QrcodeScanner } from "html5-qrcode";
import { Navigate, useNavigate } from "react-router-dom";
import barcode from "../images/barcode.png";

const BarCodeScanner = () => {
  const [scanResult, setScanResult] = useState(null);
  const [isScanning, setIsScanning] = useState(false);
  const navigate = useNavigate();

  const scannerRef = useRef(null);

  const handleScan = () => {
    setIsScanning(true);

    scannerRef.current.render(success);

    function success(result) {
      scannerRef.current.clear();
      setScanResult(result);
      setIsScanning(false);
    }

    // function error(err) {
    //   console.warn(err);
    // }
  };

  useEffect(() => {
    const scanner = new Html5QrcodeScanner("reader", {
      fps: 10,
      qrbox: 300,
    });

    scannerRef.current = scanner;

    return () => {
      if (scannerRef.current) {
        scannerRef.current.clear(); // Stop the scanner when the component unmounts
      }
    }
  }, []);

  return (
    <div className="w-full mt-4 p-4 rounded-md text-xs text-gray-700 bg-white shadow-md text-center">
      <p className="text-xl font-semibold leading-10 mb-6">Scan QR Code</p>
      {scanResult ? (
        // <div>
        //   Success: <a href={"http://" + scanResult}>{scanResult}</a>
        // </div>
        <Navigate to={`${scanResult}`} state={scanResult} />
      ) : (
        <div className="flex justify-center relative">
          <div className="h-96 w-96" id="reader"></div>
          {!isScanning && <img className="absolute sm:w-96 w-52" src={barcode} alt="barcode" />}
        </div>
      )}
      <button
        className="bg-teal-400 rounded px-4 py-1 text-white font-semibold hover:bg-teal-500"
        onClick={handleScan}
      >
        Start Scaning
      </button>
    </div>
  );
};

export default BarCodeScanner;
