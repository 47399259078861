import React, { useState } from 'react';
import { Outlet, Navigate } from 'react-router-dom';
import Navbar from './Navbar';
import Sidebar from './Sidebar';

const PrivateRoutesWithLayout = () => {

    const [open, setOpen] = useState(false);
    const isloggedIn = true;

    const toggleOpen = () => {
        setOpen(!open);
    };

    return (
        <>
            {isloggedIn ? (
            <>
                <div className="fixed top-0 left-0 w-full z-40"><Navbar toggleOpen={toggleOpen} open={open}/></div>
                <div className="">
                    <div className="flex">
                        {/* <div className={`h-screen overflow-y-auto transition-all duration-700 z-20 ${open ? 'w-64' : 'w-16'}`}> */}
                        <div className={`fixed h-screen overflow-y-auto z-20 w-fit`}>
                            <Sidebar open={open} />
                        </div>
                        {/* <div className={`h-screen w-16 transition-all duration-700`}></div> */}
                        <div className="flex-1 pt-14 p-4 ml-14 bg-gray-50 overflow-auto min-h-screen">
                            <Outlet />
                        </div>
                    </div>
                </div>
            </>) : <Navigate to="/login" />}
        </>
    );
}

export default PrivateRoutesWithLayout;
