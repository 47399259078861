import React, { useState, useEffect, useRef } from 'react';
import { Calendar } from '@fullcalendar/core';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import listPlugin from '@fullcalendar/list';
import ManageProductionHour from '../modals/ManageProductionHour';

const CalendarTask = () => {

  const calendarRef = useRef(null);
  const [open, setOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState("");
  const [manageHour, setManageHour] = useState(false);

  useEffect(() => {
    const calendar = new Calendar(calendarRef.current, {
      plugins: [dayGridPlugin, timeGridPlugin, listPlugin],
      initialView: 'dayGridMonth',
      selectable: true,
      headerToolbar: {
        left: 'prev,next today',
        center: 'title',
        right: 'dayGridMonth,timeGridWeek,timeGridDay,listWeek'
      },
      events: [
        {
          title: 'Long Event',
          start: '2023-07-07',
          end: '2023-07-12',
          person: 'Person A',
        },
        {
          title: 'All Day Event',
          start: '2023-07-11',
          person: 'Person B',
        },
        {
          groupId: 999,
          title: 'Repeating Event',
          start: '2023-07-09T16:00:00',
          person: 'Person C',
        },
      ],

        // eventClick: function(info) {
        //     alert('Event: ' + info.event.title);
        // //     // info.el.style.borderColor = 'red';
        // },
        // dateClick: function(info) {
        //     alert('clicked ' + info.dateStr);
        //   },
        select: () => console.log("Hello"),
        dateclick: function(info) {
            alert('Clicked on: ' + info.dateStr);
            alert('Coordinates: ' + info.jsEvent.pageX + ',' + info.jsEvent.pageY);
            alert('Current view: ' + info.view.type);
            // change the day's background color just for fun
            info.dayEl.style.backgroundColor = 'red';
          }
        
    });

    calendar.render();

    return () => {
      calendar.destroy();
    };
  }, []);

//   const handleDateSelect = (selectInfo) => {
//     console.log(selectInfo);
//     };

  return (
    <div className='w-full p-4 text-gray-700 bg-white shadow-md rounded-md mt-4'>
      {manageHour && <ManageProductionHour open={() => setManageHour(false)} />}
      <div className="flex justify-end pb-4 mb-4 gap-4 text-sm border-b">
        <div className="flex items">
          <select
            value={selectedOption}
            onChange={(e) => setSelectedOption(e.target.value)}
            className="block appearance-none w-full bg-white border border-gray-400 hover:border-gray-500 px-4 py-1 rounded shadow leading-tight focus:outline-none focus:shadow-outline"
          >
            <option value="">Operator</option>
            <option value="option1">Option 1</option>
            <option value="option2">Option 2</option>
            <option value="option3">Option 3</option>
          </select>
          {/* <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24"><g transform="rotate(180 12 12)"><path fill="currentColor" d="M3 19h18a1.002 1.002 0 0 0 .823-1.569l-9-13c-.373-.539-1.271-.539-1.645 0l-9 13A.999.999 0 0 0 3 19z"/></g></svg> */}
        </div>
        <button onClick={() => setManageHour(true)} className="px-4 py-1 text-white bg-indigo-600 hover:bg-indigo-500 rounded-md">Manage Production Hours</button>
      </div>
      <div ref={calendarRef} />
    </div>
  );
};

export default CalendarTask;

