import React from "react";
import { Link, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";

const Sidebar = ({ open }) => {
  const location = useLocation();
  const permissions = useSelector((state) => state.userData.user?.permissions);

  const handleLogout = () => {
    localStorage.removeItem("token");
  };

  return (
    <div
      className={`h-screen overflow-y-auto bg-slate-900 transition-all duration-700 text-slate-400 px-2 pt-20 font-medium relative ${
        open ? "w-64" : "w-14"
      }`}
    >
      <ul className="leading-10 space-y-1">
        {permissions?.includes("Roles and Permissions") && (
          <Link
            to="/"
            className={`hover:bg-slate-800 hover:text-white cursor-pointer rounded-md px-2 flex items-center gap-3 h-10 ${
              location.pathname === "/" ? "text-white bg-slate-800" : ""
            }`}
            title="Roles and Permission"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="16"
              viewBox="0 0 640 512"
            >
              <path
                fill="currentColor"
                d="M224 256a128 128 0 1 0 0-256a128 128 0 1 0 0 256zm-45.7 48C79.8 304 0 383.8 0 482.3C0 498.7 13.3 512 29.7 512h388.6c10 0 18.8-4.9 24.2-12.5l-99.2-99.2c-14.9-14.9-23.3-35.1-23.3-56.1v-33c-15.9-4.7-32.8-7.2-50.3-7.2h-91.4zM384 224c-17.7 0-32 14.3-32 32v82.7c0 17 6.7 33.3 18.7 45.3l107.4 107.3c18.7 18.7 49.1 18.7 67.9 0l73.4-73.4c18.7-18.7 18.7-49.1 0-67.9L512 242.7c-12-12-28.3-18.7-45.3-18.7H384zm24 80a24 24 0 1 1 48 0a24 24 0 1 1-48 0z"
              />
            </svg>
            {/* {open && <p className={`transition-all duration-700 delay-700 ${open ? 'w-fit' : 'w-0'}`}>Roles and Permission</p>} */}
            <p
              className={`duration-300 origin-left whitespace-nowrap ${
                !open && "hidden"
              }`}
            >
              Roles and Permission
            </p>
          </Link>
        )}
        {permissions?.includes("Emplyoee/Resource") && (
          <Link
            to="/employee-resource"
            className={`hover:bg-slate-800 hover:text-white cursor-pointer rounded-md px-2 flex items-center gap-3 h-10 ${
              location.pathname === "/employee-resource"
                ? "text-white bg-slate-800"
                : ""
            }`}
            title="Emplyoee/Resource"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="20"
              viewBox="0 0 20 20"
            >
              <path
                fill="currentColor"
                d="M13 6a3 3 0 1 1-6 0a3 3 0 0 1 6 0Zm5 2a2 2 0 1 1-4 0a2 2 0 0 1 4 0Zm-4 7a4 4 0 0 0-8 0v3h8v-3ZM6 8a2 2 0 1 1-4 0a2 2 0 0 1 4 0Zm10 10v-3a5.972 5.972 0 0 0-.75-2.906A3.005 3.005 0 0 1 19 15v3h-3ZM4.75 12.094A5.973 5.973 0 0 0 4 15v3H1v-3a3 3 0 0 1 3.75-2.906Z"
              />
            </svg>
            <p
              className={`duration-300 origin-left whitespace-nowrap ${
                !open && "hidden"
              }`}
            >
              Employee/Resource
            </p>
          </Link>
        )}
        {permissions?.includes("Calendar") && (
          <Link
            to="/calendar"
            className={`hover:bg-slate-800 hover:text-white cursor-pointer rounded-md px-2 flex items-center gap-3 h-10 ${
              location.pathname === "/calendar" ? "text-white bg-slate-800" : ""
            }`}
            title="Calendar"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="20"
              viewBox="0 0 24 24"
            >
              <path
                fill="currentColor"
                d="M19 4h-2V3a1 1 0 0 0-2 0v1H9V3a1 1 0 0 0-2 0v1H5a3 3 0 0 0-3 3v12a3 3 0 0 0 3 3h14a3 3 0 0 0 3-3V7a3 3 0 0 0-3-3Zm1 15a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1v-7h16Zm0-9H4V7a1 1 0 0 1 1-1h2v1a1 1 0 0 0 2 0V6h6v1a1 1 0 0 0 2 0V6h2a1 1 0 0 1 1 1Z"
              />
            </svg>
            <p
              className={`duration-300 origin-left whitespace-nowrap ${
                !open && "hidden"
              }`}
            >
              Calendar
            </p>
          </Link>
        )}
        {permissions?.includes("Jobs management") && (
          <Link
            to="/jobs-management"
            className={`hover:bg-slate-800 hover:text-white cursor-pointer rounded-md px-2 flex items-center gap-3 h-10 ${
              location.pathname === "/jobs-management"
                ? "text-white bg-slate-800"
                : ""
            }`}
            title="Jobs management"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="20"
              viewBox="0 0 32 32"
            >
              <path
                fill="currentColor"
                d="M15 20H9a3 3 0 0 0-3 3v2h2v-2a1 1 0 0 1 1-1h6a1 1 0 0 1 1 1v2h2v-2a3 3 0 0 0-3-3zm-3-1a4 4 0 1 0-4-4a4 4 0 0 0 4 4zm0-6a2 2 0 1 1-2 2a2 2 0 0 1 2-2z"
              />
              <path
                fill="currentColor"
                d="M28 19v9H4V8h12V6H4a2 2 0 0 0-2 2v20a2 2 0 0 0 2 2h24a2 2 0 0 0 2-2v-9Z"
              />
              <path
                fill="currentColor"
                d="M20 19h6v2h-6zm2 4h4v2h-4zm10-13V8h-2.101a4.968 4.968 0 0 0-.732-1.753l1.49-1.49l-1.414-1.414l-1.49 1.49A4.968 4.968 0 0 0 26 4.101V2h-2v2.101a4.968 4.968 0 0 0-1.753.732l-1.49-1.49l-1.414 1.414l1.49 1.49A4.968 4.968 0 0 0 20.101 8H18v2h2.101a4.968 4.968 0 0 0 .732 1.753l-1.49 1.49l1.414 1.414l1.49-1.49a4.968 4.968 0 0 0 1.753.732V16h2v-2.101a4.968 4.968 0 0 0 1.753-.732l1.49 1.49l1.414-1.414l-1.49-1.49A4.968 4.968 0 0 0 29.899 10zm-7 2a3 3 0 1 1 3-3a3.003 3.003 0 0 1-3 3z"
              />
            </svg>
            <p
              className={`duration-300 origin-left whitespace-nowrap ${
                !open && "hidden"
              }`}
            >
              Jobs Management
            </p>
          </Link>
        )}
        {permissions?.includes("Tasks management") && (
          <Link
            to="/tasks-management"
            className={`hover:bg-slate-800 hover:text-white cursor-pointer rounded-md px-2 flex items-center gap-3 h-10 ${
              location.pathname === "/tasks-management"
                ? "text-white bg-slate-800"
                : ""
            }`}
            title="Tasks management"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="20"
              viewBox="0 0 16 16"
            >
              <path
                fill="currentColor"
                d="M2 2h4a1 1 0 0 1 1 1v4a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V3a1 1 0 0 1 1-1Zm4.655 8.595a.75.75 0 0 1 0 1.06L4.03 14.28a.75.75 0 0 1-1.06 0l-1.5-1.5a.749.749 0 0 1 .326-1.275a.749.749 0 0 1 .734.215l.97.97l2.095-2.095a.75.75 0 0 1 1.06 0ZM9.75 2.5h5.5a.75.75 0 0 1 0 1.5h-5.5a.75.75 0 0 1 0-1.5Zm0 5h5.5a.75.75 0 0 1 0 1.5h-5.5a.75.75 0 0 1 0-1.5Zm0 5h5.5a.75.75 0 0 1 0 1.5h-5.5a.75.75 0 0 1 0-1.5Zm-7.25-9v3h3v-3Z"
              />
            </svg>
            <p
              className={`duration-300 origin-left whitespace-nowrap ${
                !open && "hidden"
              }`}
            >
              Tasks Management
            </p>
          </Link>
        )}
        {permissions?.includes("Leave request") && (
          <Link
            to="/leave-requests"
            className={`hover:bg-slate-800 hover:text-white cursor-pointer rounded-md px-2 flex items-center gap-3 h-10 ${
              location.pathname === "/leave-requests"
                ? "text-white bg-slate-800"
                : ""
            }`}
            title="Leave request"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="20"
              viewBox="0 0 24 24"
            >
              <g fill="none" stroke="currentColor" strokeWidth="2">
                <circle cx="12" cy="7" r="5" />
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M17 22H5.266a2 2 0 0 1-1.985-2.248l.39-3.124A3 3 0 0 1 6.649 14H7"
                />
                <path strokeLinecap="round" d="m22 19l-5-5m5 0l-5 5" />
              </g>
            </svg>
            <p
              className={`duration-300 origin-left whitespace-nowrap ${
                !open && "hidden"
              }`}
            >
              Leave Requests
            </p>
          </Link>
        )}
        {permissions?.includes("My Account") && (
          <Link
            to="/account"
            className={`hover:bg-slate-800 hover:text-white cursor-pointer rounded-md px-2 flex items-center gap-3 h-10 ${
              location.pathname === "/account" ? "text-white bg-slate-800" : ""
            }`}
            title="My Account"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-6 h-6"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M10.343 3.94c.09-.542.56-.94 1.11-.94h1.093c.55 0 1.02.398 1.11.94l.149.894c.07.424.384.764.78.93.398.164.855.142 1.205-.108l.737-.527a1.125 1.125 0 011.45.12l.773.774c.39.389.44 1.002.12 1.45l-.527.737c-.25.35-.272.806-.107 1.204.165.397.505.71.93.78l.893.15c.543.09.94.56.94 1.109v1.094c0 .55-.397 1.02-.94 1.11l-.893.149c-.425.07-.765.383-.93.78-.165.398-.143.854.107 1.204l.527.738c.32.447.269 1.06-.12 1.45l-.774.773a1.125 1.125 0 01-1.449.12l-.738-.527c-.35-.25-.806-.272-1.203-.107-.397.165-.71.505-.781.929l-.149.894c-.09.542-.56.94-1.11.94h-1.094c-.55 0-1.019-.398-1.11-.94l-.148-.894c-.071-.424-.384-.764-.781-.93-.398-.164-.854-.142-1.204.108l-.738.527c-.447.32-1.06.269-1.45-.12l-.773-.774a1.125 1.125 0 01-.12-1.45l.527-.737c.25-.35.273-.806.108-1.204-.165-.397-.505-.71-.93-.78l-.894-.15c-.542-.09-.94-.56-.94-1.109v-1.094c0-.55.398-1.02.94-1.11l.894-.149c.424-.07.765-.383.93-.78.165-.398.143-.854-.107-1.204l-.527-.738a1.125 1.125 0 01.12-1.45l.773-.773a1.125 1.125 0 011.45-.12l.737.527c.35.25.807.272 1.204.107.397-.165.71-.505.78-.929l.15-.894z"
              />
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
              />
            </svg>

            <p
              className={`duration-300 origin-left whitespace-nowrap ${
                !open && "hidden"
              }`}
            >
              My Account
            </p>
          </Link>
        )}
        <Link
          to="/rpo-schedule"
          className={`hover:bg-slate-800 hover:text-white cursor-pointer rounded-md px-2 flex items-center gap-3 h-10 ${
            location.pathname === "/rpo-schedule"
              ? "text-white bg-slate-800"
              : ""
          }`}
          title="RPO Schedule"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="w-6 h-6"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M11.35 3.836c-.065.21-.1.433-.1.664 0 .414.336.75.75.75h4.5a.75.75 0 00.75-.75 2.25 2.25 0 00-.1-.664m-5.8 0A2.251 2.251 0 0113.5 2.25H15c1.012 0 1.867.668 2.15 1.586m-5.8 0c-.376.023-.75.05-1.124.08C9.095 4.01 8.25 4.973 8.25 6.108V8.25m8.9-4.414c.376.023.75.05 1.124.08 1.131.094 1.976 1.057 1.976 2.192V16.5A2.25 2.25 0 0118 18.75h-2.25m-7.5-10.5H4.875c-.621 0-1.125.504-1.125 1.125v11.25c0 .621.504 1.125 1.125 1.125h9.75c.621 0 1.125-.504 1.125-1.125V18.75m-7.5-10.5h6.375c.621 0 1.125.504 1.125 1.125v9.375m-8.25-3l1.5 1.5 3-3.75"
            />
          </svg>
          <p
            className={`duration-300 origin-left whitespace-nowrap ${
              !open && "hidden"
            }`}
          >
            RPO Schedule
          </p>
        </Link>
        <Link
          to="/rpo"
          className={`hover:bg-slate-800 hover:text-white cursor-pointer rounded-md px-2 flex items-center gap-3 h-10 ${
            location.pathname.startsWith("/rpo") ? "text-white bg-slate-800" : ""
          }`}
          title="RPO"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="w-6 h-6"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M2.25 13.5h3.86a2.25 2.25 0 012.012 1.244l.256.512a2.25 2.25 0 002.013 1.244h3.218a2.25 2.25 0 002.013-1.244l.256-.512a2.25 2.25 0 012.013-1.244h3.859m-19.5.338V18a2.25 2.25 0 002.25 2.25h15A2.25 2.25 0 0021.75 18v-4.162c0-.224-.034-.447-.1-.661L19.24 5.338a2.25 2.25 0 00-2.15-1.588H6.911a2.25 2.25 0 00-2.15 1.588L2.35 13.177a2.25 2.25 0 00-.1.661z"
            />
          </svg>

          <p
            className={`duration-300 origin-left whitespace-nowrap ${
              !open && "hidden"
            }`}
          >
            RPO
          </p>
        </Link>
        <Link
          to="/operator"
          className={`hover:bg-slate-800 hover:text-white cursor-pointer rounded-md px-2 flex items-center gap-3 h-10 ${
            location.pathname.startsWith("/operator") ? "text-white bg-slate-800" : ""
          }`}
          title="Operator"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="w-6 h-6"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M10.5 6a7.5 7.5 0 107.5 7.5h-7.5V6z"
            />
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M13.5 10.5H21A7.5 7.5 0 0013.5 3v7.5z"
            />
          </svg>
          <p
            className={`duration-300 origin-left whitespace-nowrap ${
              !open && "hidden"
            }`}
          >
            Operator
          </p>
        </Link>
      </ul>
      <Link
        to="/login"
        onClick={handleLogout}
        className="px-2 flex items-center gap-2 absolute bottom-2 hover:text-white h-10"
        title="Logout"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="16"
          viewBox="0 0 512 512"
        >
          <path
            fill="currentColor"
            d="M288 32c0-17.7-14.3-32-32-32s-32 14.3-32 32v224c0 17.7 14.3 32 32 32s32-14.3 32-32V32zm-144.5 88.6c13.6-11.3 15.4-31.5 4.1-45.1s-31.5-15.4-45.1-4.1C49.7 115.4 16 181.8 16 256c0 132.5 107.5 240 240 240s240-107.5 240-240c0-74.2-33.8-140.6-86.6-184.6c-13.6-11.3-33.8-9.4-45.1 4.1s-9.4 33.8 4.1 45.1c38.9 32.3 63.5 81 63.5 135.4c0 97.2-78.8 176-176 176s-176-78.8-176-176c0-54.4 24.7-103.1 63.5-135.4z"
          />
        </svg>
        <p
          className={`duration-300 origin-left whitespace-nowrap ${
            !open && "hidden"
          }`}
        >
          Logout
        </p>
      </Link>
    </div>
  );
};

export default Sidebar;
