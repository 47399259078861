import React, { useState } from "react";
import logo from "../../images/logo.png";
import { Link, useNavigate } from "react-router-dom";
// import useLogin, { login } from '../../hook/useLogin';
import { useDispatch } from "react-redux";
import { setUser } from "../../redux/user";

const Login = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errors, setErrors] = useState([]);

  const handleLogin = async (e) => {
    e.preventDefault();

    const res = await fetch("https://ampac.atpldhaka.com/api/auth/login", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ email, password }),
    });

    const data = await res.json();
    if (data.errors) {
      setErrors(data.errors);
    }
    if (data.status === true) {
      fetch("https://ampac.atpldhaka.com/api/profile", {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + data.token,
        },
      })
        .then((res) => res.json())
        .then((data) => {
          dispatch(setUser(data));
        })
        .catch((err) => console.log(err));

      localStorage.setItem("token", data.token);
      navigate("/");
    }
  };

  // const { data, isloading } = useLogin();

  return (
    <div className="flex min-h-screen flex-1 flex-col justify-center sm:px-6 py-12 lg:px-8">
      <div className="shadow-lg mx-auto px-4 py-6 rounded sm:w-96 w-10/12">
        <div className="sm:mx-auto sm:w-full sm:max-w-sm">
          <img
            className="mx-auto h-10 w-auto"
            src={logo}
            alt="Your Company logo"
          />
          <h2 className="mt-6 text-center text-2xl font-bold leading-4 tracking-tight text-gray-900">
            Sign in to your account
          </h2>
        </div>

        <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm px-4">
          <div className="space-y-4">
            <div>
              <label
                htmlFor="email"
                className="block text-sm font-medium leading-4 text-gray-900"
              >
                Email address <span className="text-rose-400">*</span>
              </label>
              <div className="mt-2">
                <input
                  onChange={(e) => {
                    setEmail(e.target.value);
                    setErrors([]);
                  }}
                  type="email"
                  autoComplete="email"
                  required
                  // value="admin@gmail.com"
                  className="border-b w-full outline-none pb-1 px-2 focus:border-indigo-600"
                />
                <p className="text-sm text-rose-400">{errors?.email}</p>
              </div>
            </div>

            <div>
              <label
                htmlFor="password"
                className="block text-sm font-medium leading-4 text-gray-900"
              >
                Password <span className="text-rose-400">*</span>
              </label>
              <div className="mt-2">
                <input
                  onChange={(e) => {
                    setPassword(e.target.value);
                    setErrors([]);
                  }}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") handleLogin(e);
                  }}
                  type="password"
                  autoComplete="current-password"
                  required
                  className="border-b w-full outline-none pb-1 px-2 focus:border-indigo-600"
                />
                <p className="text-sm text-rose-400">{errors?.password}</p>
              </div>
            </div>

            <Link
              to="/forgetpass"
              className="text-sm float-right leading-3 font-semibold text-indigo-600 hover:text-indigo-500"
            >
              Forgot password?
            </Link>

            <button
              onClick={handleLogin}
              // onClick={() => login(email, password)}
              className="w-full mt-4 rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500"
            >
              Sign in
            </button>
          </div>

          {/* <p className="mt-6 text-center text-sm text-gray-500">
            Not a member?{' '}
            <Link to="/register" className="font-semibold leading-6 text-indigo-600 hover:text-indigo-500">
              Create an account
            </Link>
          </p> */}
        </div>
      </div>
    </div>
  );
};

export default Login;
