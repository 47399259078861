import React, { useEffect, useState } from "react";
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import BarCodeScanner from "../BarCodeScanner";

const RPO = () => {
  const [row, setRow] = useState(1);
  const [rows, setRows] = useState([]);
  const [rpo, setRpo] = useState('');
  const [rpoList, setRpoList] = useState([]);
  const [dca, setDca] = useState('');
  const [dcaTemp, setDcaTemp] = useState('');
  const [dataRPO, setDataRPO] = useState([]);

  useEffect(() => {
    if(dca){
      fetchRPO();
    }
  }, [dca]);

  const fetchRPO = async () => {
    const res = await fetch(`https://ampac.atpldhaka.com/api/rpo-details/${dca}`);
    const data = await res.json();
    setDataRPO(data.RPOSchedule);
    setRpo(data.RPOSchedule[0])
    setRpoList(data.RPOSchedule.map((item) => item.RPONo));
    if (data.RPOSchedule.length === 0 || data.error)
      console.log("No data found");
    console.log("call");
  }

  const handleRPOChange = (event) => {
    setRpo(dataRPO.find((item) => item.RPONo === event.target.value));
  }

  return (
    // !dataRPO.length ? <div className="w-full mt-4 p-4 rounded-md text-xs text-gray-700 bg-white shadow-md">
    //   <div className="flex flex-wrap gap-2">
    //       <p className="font-bold py-1">Select Part No</p>
    //       <input className="p-1 border outline-none" value={'DCA47850'} onKeyDown={(e) => {if(e.key === 'Enter') {setDca(e.target.value); setDcaTemp(e.target.value)}}} placeholder="Part No"/>
    //   </div>
    // </div>
    !dataRPO.length ?
    <BarCodeScanner />
    :
    <div className="w-full mt-4 p-4 rounded-md text-xs text-gray-700 bg-white shadow-md">
      <div className="flex flex-wrap gap-2 leading-6">
        <div className="w-32">
          <p className="font-bold">Part No</p>
          {/* <input className="w-full p-1 border outline-none" value={dcaTemp} onChange={(e) => setDcaTemp(e.target.value)} onKeyDown={(e) => {if(e.key === 'Enter') setDca(e.target.value)}} placeholder="Part No"/> */}
          <p>{dcaTemp}</p>
        </div>
        <div className="w-32">
          <Box
            component="form"
            sx={{
              '& .MuiTextField-root': {
                mt: 1,
                width: '90%',
                '& .MuiInputBase-input': {
                  fontSize: '12px',
                },
              },
              '& .MuiMenuItem-root': {
                fontSize: '10px', // Adjust font size for MenuItems
              },
            }}
            noValidate
            autoComplete="off"
          >
            <div>
              <TextField
                id="outlined-select-currency"
                select
                label="Select RPO"
                size="small"
                defaultValue={rpoList[0]}
                onChange={handleRPOChange}
              >
                {rpoList?.map((item, index)=> (
                  <MenuItem key={index} value={item} sx={{ fontSize: '12px' }}>
                    {item}
                  </MenuItem>
                ))}
              </TextField>
            </div>
          </Box>
          {/* <p className="font-bold">RPO</p>
          <input className="w-full p-1 border outline-none" onChange={(e) => setRpo(e.target.value)} value={rpo} /> */}
        </div>
        <div className="w-32">
          <p className="font-bold">Ord Qty</p>
          <p className="">{rpo.OrdQty}</p>
        </div>
        <div className="w-32">
          <p className="font-bold">OutstdQty</p>
          <p className="">{rpo.OutstdQty}</p>
        </div>
        <div className="w-32">
          <p className="font-bold">Total Allo Time (min)</p>
          <p className="">{Number(rpo.AlloHours)}</p>
        </div>
        <div className="w-32">
          <p className="font-bold">ID</p>
          <p className="">{rpo.id}</p>
        </div>
        <div className="w-32">
          <p className="font-bold">CRSD</p>
          <p className="">{rpo.CRSD}</p>
        </div>
        <div className="w-32">
          <p className="font-bold">ACSD</p>
          <p className="">{rpo.ACSD}</p>
        </div>
        <div className="w-32">
          <p className="font-bold">Date Completed</p>
          <p className=""></p>
        </div>
        <div className="w-32">
          <p className="font-bold">Actual Time (min)</p>
          <p className=""></p>
        </div>
        <div className="w-32">
          <p className="font-bold">Efficiency</p>
          <p className=""></p>
        </div>
        <div className="w-32">
          <p className="font-bold">IntOrdNo</p>
          <p className="">{rpo.IntOrdNo}</p>
        </div>
        <div className="w-32">
          <p className="font-bold">Supervisor</p>
          <p className=""></p>
        </div>
        <div className="w-32">
          <p className="font-bold">ProdStartDate</p>
          <p className="">{rpo.ProdStartDate.split(" ")[0]}</p>
        </div>
        <div className="w-32">
          <p className="font-bold">EstCompDate</p>
          <p className="">{rpo.EstCompDate}</p>
        </div>
        <div className="w-32">
          <p className="font-bold">Kit Status</p>
          <p className="">{rpo.KitStatus}</p>
        </div>
        <div className="w-32">
          <p className="font-bold">Status</p>
          <p className="">{rpo.StatusFlag}</p>
        </div>
      </div>
      <div className="my-5 overflow-x-auto scroll-efffect-horizontal border">
        <div className="flex justify-between min-w-fit font-bold leading-6">
          <p className="w-8 p-1">No</p>
          <p className="w-20 p-1 ">Process</p>
          <p className="w-24 p-1">Date</p>
          <p className="w-24 p-1">Operator</p>
          <p className="w-28 p-1">Allo Time (min)</p>
          <p className="w-24 p-1">Time Start</p>
          <p className="w-24 p-1">Time End</p>
          <p className="w-20 p-1">Actual Time</p>
          <p className="w-20 p-1">Qty</p>
          <p className="w-24 p-1">Status</p>
          <p className="w-32 p-1">Comments</p>
        </div>
        {/* <div className="flex justify-between min-w-fit"> */}
        {Array.from({ length: row }).map((_, index) => (
          <div
            key={index}
            className="flex items-center justify-between min-w-fit border-t text-left"
          >
            <p className="w-8 p-1 text-right">{index + 1}</p>
            <div className="w-20 p-1 overflow-y-auto">
              {/* <input
                type="text"
                className="w-full p-1 border shadow rounded-sm"
              /> */}
              <select
                className="outline-none border w-full rounded-sm p-1 shadow max-h-40"
                // value={jobType}
                // onChange={(e) => setJobType(e.target.value)}
              >
                <option value=""></option>
                <option value="part-time">Kit</option>
                <option value="part-time">Crimp</option>
                <option value="full-time">Cut</option>
                <option value="full-time">Strip</option>
                <option value="full-time">Solder</option>
                <option value="full-time">Assemble</option>
                <option value="full-time">Test</option>
                <option value="full-time">Pack</option>
                <option value="full-time">Custom</option>
                <option value="full-time">Tin</option>
                <option value="full-time">Sample</option>
                <option value="full-time">Test/Pack</option>
                <option value="full-time">Clean</option>
                <option value="full-time">Rework</option>
                <option value="full-time">CNC</option>
              </select>
            </div>
            <div className="w-24 p-1">
              <input
                type="date"
                className="w-full p-1 border shadow rounded-sm"
              />
            </div>
            <div className="w-24 p-1">
              <select
                className="outline-none border w-full rounded-sm p-1 shadow max-h-40"
                // value={jobType}
                // onChange={(e) => setJobType(e.target.value)}
              >
                <option value=""></option>
                <option value="part-time">Imran</option>
                <option value="part-time">Jamil</option>
                <option value="full-time">Habib</option>
                <option value="full-time">Ony</option>
                <option value="full-time">Rahat</option>
                <option value="full-time">Shawon</option>
                <option value="full-time">Safwon</option>
                <option value="full-time">Rahad</option>
                <option value="full-time">Jannatul</option>
              </select>
            </div>
            <div className="w-28 p-1">
              <input
                type="number"
                className="w-full p-1 border shadow rounded-sm"
              />
            </div>
            <div className="w-24 p-1">
              <input
                type="date"
                className="w-full p-1 border shadow rounded-sm"
              />
            </div>
            <div className="w-24 p-1">
              <input
                type="date"
                className="w-full p-1 border shadow rounded-sm"
              />
            </div>
            <div className="w-20 p-1">
              <input
                type="number"
                className="w-full p-1 border shadow rounded-sm"
              />
            </div>
            <div className="w-20 p-1">
              <input
                type="number"
                className="w-full p-1 border shadow rounded-sm"
              />
            </div>
            <div className="w-24 p-1">
              <select
                className="outline-none border w-full rounded-sm p-1 shadow max-h-40"
                // value={jobType}
                // onChange={(e) => setJobType(e.target.value)}
              >
                <option value=""></option>
                <option value="part-time">Nine</option>
                <option value="part-time">WIP</option>
                <option value="full-time">Paused</option>
                <option value="full-time">Completed</option>
                <option value="full-time">Cancled</option>
              </select>
            </div>
            <div className="w-32 p-1">
              <input
                type="text"
                className="w-full p-1 border shadow rounded-sm"
              />
            </div>
          </div>
        ))}
        {/* </div> */}
      </div>
      <button
        onClick={() => setRow((prevState) => prevState + 1)}
        className="bg-green-400 hover:bg-green-500 rounded py-2 px-6 text-white font-semibold"
      >
        + ADD ROW
      </button>
    </div>
    
  );
};

export default RPO;
