import React, { useState, useEffect } from "react";
import JobsFilter from "../modals/JobsFilter";
import { useNavigate } from "react-router-dom";
import ReactPaginate from "react-paginate";
// import jobs from '../../fetchers/jobs';

const Jobs = () => {
  const [filterJob, setFilterJob] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [currentData, setCurrentData] = useState([]);
  const [filter, setFilter] = useState(0);
  const [data, setData] = useState([]);
  const [rPOFilter, setRPOFilter] = useState("");
  const [openFilterModal, setOpenFilterModal] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem("token");

    const fetchData = async () => {
      try {
        const response = await fetch("https://ampac.atpldhaka.com/api/rpo", {
          headers: {
            Accept: "application/json",
            Authorization: "Bearer " + token,
          },
        });
        const data = await response.json();
        setData(data);
        // setLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        // setLoading(false);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    if (data.length > 0) {
      const itemsPerPage = 10;
      const indexOfLastItem = currentPage * itemsPerPage;
      const indexOfFirstItem = indexOfLastItem - itemsPerPage;
      setCurrentData(data.slice(indexOfFirstItem, indexOfLastItem));
    }
  }, [currentPage, data]);

  const handlePageClick = ({ selected }) => {
    setCurrentPage(selected + 1);
  };

  const openView = (item) => {
    navigate("view-job", { state: { data: item } });
  };

  const handleFilterChange = (value) => {
    setFilter(value);
    console.log(value);
  };

  const handleFilter = (e) => {
    if (e.keyCode === 13) {
      console.log(rPOFilter);
    }
  };

  return (
    <div className="w-full mt-4 p-4 rounded-md text-gray-700 bg-white shadow-md">
      {filterJob && (
        <JobsFilter
          filterJob={() => setFilterJob(false)}
          onFilterChange={handleFilterChange}
        />
      )}
      <div className="sm:flex justify-between items-center">
        <p className="text-xl font-semibold leading-10">Jobs Management</p>
        <div className="flex gap-4 items-center relative">
          {/* <input onChange={(e) => setSearch(e.target.value)} className='border rounded-sm px-1 py-1 outline-none focus:ring-1 ring-indigo-600' type="text" placeholder='Search'/> */}
          <button
            onClick={() => setFilterJob(true)}
            className="border px-4 py-1 text-white bg-indigo-600 hover:bg-indigo-500 rounded"
          >
            Filter
          </button>
          {filter > 0 && (
            <div className="absolute text-rose-500 px-[7px] rounded-full bg-yellow-300 right-1 -top-1 text-[12px]">
              {filter}
            </div>
          )}
        </div>
      </div>

      {/* Table width 1576px - 120px = 1576px ; */}
      <div className="scroll-efffect-horizontal overflow-x-auto mt-4 text-[12px]">
        <div className="flex justify-between text-indigo-600 leading-6 py-1 border-x bg-gray-100 min-w-[1576px]">
          <p className="w-8"></p>
          <p className="w-24">RPO Number</p>
          <p className="w-28">Customer Name</p>
          <p className="w-20">Part Number</p>
          <p className="w-16">Order Value</p>
          <p className="w-28">Customer Requested Ship Date</p>
          <p className="w-24">Ampec Confirmed Ship Date</p>
          <p className="w-16">Start Date</p>
          <p className="w-24">Estimated Completion Date</p>
          <p className="w-28">Assigned Supervisor</p>
          <p className="w-24">Expected Completion Data</p>
          <p className="w-28">Status</p>
          <p className="w-24">Parts</p>
          <p className="w-24">Wire Cutting</p>
          <p className="w-24">Crimping</p>
          <p className="w-24">CSP</p>
        </div>
        <div className="flex justify-between text-indigo-600 leading-6 pb-2 border-x bg-gray-100 min-w-[1576px]">
          <div className="w-8"></div>
          <div className="w-24 relative">
            <input
              onKeyDown={handleFilter}
              onChange={(e) => setRPOFilter(e.target.value)}
              className="bg-white outline-none border-b border-indigo-300 focus:border-indigo-600 pl-1 pr-5 w-full py-1 shadow"
              type="text"
              placeholder="Filter RPO Number"
            />
            <button
              onClick={() => setOpenFilterModal(true)}
              className="absolute right-1 mt-[9px]"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="14"
                height="14"
                viewBox="0 0 512 512"
              >
                <path
                  fill="currentColor"
                  d="M238.627 496H192V253.828l-168-200V16h456v37.612l-160 200v161.015ZM224 464h1.373L288 401.373V242.388L443.51 48H60.9L224 242.172Z"
                />
              </svg>
            </button>
          </div>
          <div className="w-28 relative">
            <input
              onChange={() => setRPOFilter}
              className="bg-white outline-none border-b border-indigo-300 focus:border-indigo-600 pl-1 pr-5 w-full py-1 shadow"
              type="text"
              placeholder="Filter Customer Name"
            />
            <button
              onClick={handleFilter}
              className="absolute right-1 mt-[9px]"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="14"
                height="14"
                viewBox="0 0 512 512"
              >
                <path
                  fill="currentColor"
                  d="M238.627 496H192V253.828l-168-200V16h456v37.612l-160 200v161.015ZM224 464h1.373L288 401.373V242.388L443.51 48H60.9L224 242.172Z"
                />
              </svg>
            </button>
          </div>
          <div className="w-20 relative">
            <input
              onChange={() => setRPOFilter}
              className="bg-white outline-none border-b border-indigo-300 focus:border-indigo-600 pl-1 pr-5 w-full py-1 shadow"
              type="text"
              placeholder="Filter Port Number"
            />
            <button
              onClick={handleFilter}
              className="absolute right-1 mt-[9px]"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="14"
                height="14"
                viewBox="0 0 512 512"
              >
                <path
                  fill="currentColor"
                  d="M238.627 496H192V253.828l-168-200V16h456v37.612l-160 200v161.015ZM224 464h1.373L288 401.373V242.388L443.51 48H60.9L224 242.172Z"
                />
              </svg>
            </button>
          </div>
          <div className="w-16 relative">
            <input
              onChange={() => setRPOFilter}
              className="bg-white outline-none border-b border-indigo-300 focus:border-indigo-600 pl-1 pr-5 w-full py-1 shadow"
              type="text"
              placeholder="Filter Order Value"
            />
            <button
              onClick={handleFilter}
              className="absolute right-1 mt-[9px]"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="14"
                height="14"
                viewBox="0 0 512 512"
              >
                <path
                  fill="currentColor"
                  d="M238.627 496H192V253.828l-168-200V16h456v37.612l-160 200v161.015ZM224 464h1.373L288 401.373V242.388L443.51 48H60.9L224 242.172Z"
                />
              </svg>
            </button>
          </div>
          <div className="w-28 relative">
            <input
              onChange={() => setRPOFilter}
              className="bg-white outline-none border-b border-indigo-300 focus:border-indigo-600 pl-1 pr-5 w-full py-1 shadow"
              type="text"
              placeholder="Filter CRSD"
            />
            <button
              onClick={handleFilter}
              className="absolute right-1 mt-[9px]"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="14"
                height="14"
                viewBox="0 0 512 512"
              >
                <path
                  fill="currentColor"
                  d="M238.627 496H192V253.828l-168-200V16h456v37.612l-160 200v161.015ZM224 464h1.373L288 401.373V242.388L443.51 48H60.9L224 242.172Z"
                />
              </svg>
            </button>
          </div>
          <div className="w-24 relative">
            <input
              onChange={() => setRPOFilter}
              className="bg-white outline-none border-b border-indigo-300 focus:border-indigo-600 pl-1 pr-5 w-full py-1 shadow"
              type="text"
              placeholder="Filter ACSD"
            />
            <button
              onClick={handleFilter}
              className="absolute right-1 mt-[9px]"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="14"
                height="14"
                viewBox="0 0 512 512"
              >
                <path
                  fill="currentColor"
                  d="M238.627 496H192V253.828l-168-200V16h456v37.612l-160 200v161.015ZM224 464h1.373L288 401.373V242.388L443.51 48H60.9L224 242.172Z"
                />
              </svg>
            </button>
          </div>
          <div className="w-16 relative">
            <input
              onChange={() => setRPOFilter}
              className="bg-white outline-none border-b border-indigo-300 focus:border-indigo-600 pl-1 pr-5 w-full py-1 shadow"
              type="text"
              placeholder="Filter Start Date"
            />
            <button
              onClick={handleFilter}
              className="absolute right-1 mt-[9px]"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="14"
                height="14"
                viewBox="0 0 512 512"
              >
                <path
                  fill="currentColor"
                  d="M238.627 496H192V253.828l-168-200V16h456v37.612l-160 200v161.015ZM224 464h1.373L288 401.373V242.388L443.51 48H60.9L224 242.172Z"
                />
              </svg>
            </button>
          </div>
          <div className="w-24 relative">
            <input
              onChange={() => setRPOFilter}
              className="bg-white outline-none border-b border-indigo-300 focus:border-indigo-600 pl-1 pr-5 w-full py-1 shadow"
              type="text"
              placeholder="Filter Estimated Completion Date"
            />
            <button
              onClick={handleFilter}
              className="absolute right-1 mt-[9px]"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="14"
                height="14"
                viewBox="0 0 512 512"
              >
                <path
                  fill="currentColor"
                  d="M238.627 496H192V253.828l-168-200V16h456v37.612l-160 200v161.015ZM224 464h1.373L288 401.373V242.388L443.51 48H60.9L224 242.172Z"
                />
              </svg>
            </button>
          </div>
          <div className="w-28 relative">
            <input
              onChange={() => setRPOFilter}
              className="bg-white outline-none border-b border-indigo-300 focus:border-indigo-600 pl-1 pr-5 w-full py-1 shadow"
              type="text"
              placeholder="Filter Supervisor"
            />
            <button
              onClick={handleFilter}
              className="absolute right-1 mt-[9px]"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="14"
                height="14"
                viewBox="0 0 512 512"
              >
                <path
                  fill="currentColor"
                  d="M238.627 496H192V253.828l-168-200V16h456v37.612l-160 200v161.015ZM224 464h1.373L288 401.373V242.388L443.51 48H60.9L224 242.172Z"
                />
              </svg>
            </button>
          </div>
          <div className="w-24 relative">
            <input
              onChange={() => setRPOFilter}
              className="bg-white outline-none border-b border-indigo-300 focus:border-indigo-600 pl-1 pr-5 w-full py-1 shadow"
              type="text"
              placeholder="Filter Expected Completion Data"
            />
            <button
              onClick={handleFilter}
              className="absolute right-1 mt-[9px]"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="14"
                height="14"
                viewBox="0 0 512 512"
              >
                <path
                  fill="currentColor"
                  d="M238.627 496H192V253.828l-168-200V16h456v37.612l-160 200v161.015ZM224 464h1.373L288 401.373V242.388L443.51 48H60.9L224 242.172Z"
                />
              </svg>
            </button>
          </div>
          <div className="w-28 relative">
            <input
              onChange={() => setRPOFilter}
              className="bg-white outline-none border-b border-indigo-300 focus:border-indigo-600 pl-1 pr-5 w-full py-1 shadow"
              type="text"
              placeholder="Filter Status"
            />
            <button
              onClick={handleFilter}
              className="absolute right-1 mt-[9px]"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="14"
                height="14"
                viewBox="0 0 512 512"
              >
                <path
                  fill="currentColor"
                  d="M238.627 496H192V253.828l-168-200V16h456v37.612l-160 200v161.015ZM224 464h1.373L288 401.373V242.388L443.51 48H60.9L224 242.172Z"
                />
              </svg>
            </button>
          </div>
          <div className="w-24 relative">
            <input
              onChange={() => setRPOFilter}
              className="bg-white outline-none border-b border-indigo-300 focus:border-indigo-600 pl-1 pr-5 w-full py-1 shadow"
              type="text"
              placeholder="Filter Parts"
            />
            <button
              onClick={handleFilter}
              className="absolute right-1 mt-[9px]"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="14"
                height="14"
                viewBox="0 0 512 512"
              >
                <path
                  fill="currentColor"
                  d="M238.627 496H192V253.828l-168-200V16h456v37.612l-160 200v161.015ZM224 464h1.373L288 401.373V242.388L443.51 48H60.9L224 242.172Z"
                />
              </svg>
            </button>
          </div>
          <div className="w-24 relative">
            <input
              onChange={() => setRPOFilter}
              className="bg-white outline-none border-b border-indigo-300 focus:border-indigo-600 pl-1 pr-5 w-full py-1 shadow"
              type="text"
              placeholder="Filter Wire Cutting"
            />
            <button
              onClick={handleFilter}
              className="absolute right-1 mt-[9px]"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="14"
                height="14"
                viewBox="0 0 512 512"
              >
                <path
                  fill="currentColor"
                  d="M238.627 496H192V253.828l-168-200V16h456v37.612l-160 200v161.015ZM224 464h1.373L288 401.373V242.388L443.51 48H60.9L224 242.172Z"
                />
              </svg>
            </button>
          </div>
          <div className="w-24 relative">
            <input
              onChange={() => setRPOFilter}
              className="bg-white outline-none border-b border-indigo-300 focus:border-indigo-600 pl-1 pr-5 w-full py-1 shadow"
              type="text"
              placeholder="Filter Crimping"
            />
            <button
              onClick={handleFilter}
              className="absolute right-1 mt-[9px]"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="14"
                height="14"
                viewBox="0 0 512 512"
              >
                <path
                  fill="currentColor"
                  d="M238.627 496H192V253.828l-168-200V16h456v37.612l-160 200v161.015ZM224 464h1.373L288 401.373V242.388L443.51 48H60.9L224 242.172Z"
                />
              </svg>
            </button>
          </div>
          <div className="w-24 relative">
            <input
              onChange={() => setRPOFilter}
              className="bg-white outline-none border-b border-indigo-300 focus:border-indigo-600 pl-1 pr-5 w-full py-1 shadow"
              type="text"
              placeholder="Filter CSP"
            />
            <button
              onClick={handleFilter}
              className="absolute right-1 mt-[9px]"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="14"
                height="14"
                viewBox="0 0 512 512"
              >
                <path
                  fill="currentColor"
                  d="M238.627 496H192V253.828l-168-200V16h456v37.612l-160 200v161.015ZM224 464h1.373L288 401.373V242.388L443.51 48H60.9L224 242.172Z"
                />
              </svg>
            </button>
          </div>
        </div>
        <div className=" leading-9 min-w-[1576px]">
          {currentData?.map((item) => (
            <div
              key={item.id}
              className="flex items-center border-x justify-between border-b border-gray-100 leading-6"
            >
              <button className="w-8 py-2 flex">
                <svg
                  onClick={() => openView(item)}
                  className="w-full m-auto fill-yellow-400 hover:fill-yellow-600"
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  viewBox="0 0 24 24"
                >
                  <path d="M12 9a3 3 0 0 0-3 3a3 3 0 0 0 3 3a3 3 0 0 0 3-3a3 3 0 0 0-3-3m0 8a5 5 0 0 1-5-5a5 5 0 0 1 5-5a5 5 0 0 1 5 5a5 5 0 0 1-5 5m0-12.5C7 4.5 2.73 7.61 1 12c1.73 4.39 6 7.5 11 7.5s9.27-3.11 11-7.5c-1.73-4.39-6-7.5-11-7.5Z" />
                </svg>
              </button>
              <p className="w-24">{item.RPONo}</p>
              <p className="w-28">{item.name}</p>
              <p className="w-20">{item.partNo}</p>
              <p className="w-16">{item.quantity}</p>
              <p className="w-28">{item.customer_requested_ship_date}</p>
              <p className="w-24">{item.acsd}</p>
              <p className="w-16">{item.start_date}</p>
              <p className="w-24">{item.estCompDate}</p>
              <p className="w-28">{item.assigned_supervisor}</p>
              <p className="w-24">{item.expected_completion_date}</p>
              <p className="w-28">{item.status}</p>
              <p className="w-24">{item.parts}</p>
              <p className="w-24">{item.wire_cutting}</p>
              <p className="w-24">{item.crimping}</p>
              <p className="w-24">{item.csp}</p>
            </div>
          ))}
        </div>
      </div>
      <ReactPaginate
        breakLabel={"..."}
        pageCount={Math.ceil((data?.length || 0) / 10)}
        pageRangeDisplayed={3}
        onPageChange={handlePageClick}
        previousLabel="Previous"
        nextLabel="Next"
        previousLinkClassName="px-2 py-px rounded-sm bg-blue-500 text-white"
        nextLinkClassName="px-2 py-px rounded-sm bg-blue-500 text-white"
        activeClassName="bg-blue-500 text-white rounded-sm"
        pageLinkClassName="p-2"
        className="flex gap-2 mt-5 justify-end"
      />
    </div>
  );
};

export default Jobs;
