import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import PropicUpload from '../modals/PropicUpload';
import ResetPassword from '../ResetPassword';

const Account = () => {

    const [editPicture, setEditPicture] = useState(false);
    const [resetPass, setResetPass] = useState(false);
    const user = useSelector(state => state.userData.user);

    const handlePassword = () => {
        console.log('password')
    }

    return (
        <>
            {resetPass ? <ResetPassword passCom={() => setResetPass(false)}/> : (
                (<div className='w-full mt-4 p-4 rounded-md text-gray-700 bg-white shadow-md'>
                {editPicture && <PropicUpload editPicture={() => setEditPicture(false)} />}
                <h2 className='text-xl font-semibold'>Account</h2>
                <div className="flex flex-wrap gap-8 items-center justify-center sm:justify-between p-8 md:px-24 border-b">
                    <div className="h-32 w-32 rounded-full border shadow flex items-center justify-center relative">
                        <img className='h-28 w-28 object-cover rounded-full' src={user?.profile.profile_picture} alt="profile" />
                        <div onClick={() => setEditPicture(true)} className='cursor-pointer absolute bottom-2 right-2 border border-teal-400 rounded-full p-1 bg-white shadow'>
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24"><path fill="#0abb8f" d="M3 17.25V21h3.75L17.81 9.94l-3.75-3.75L3 17.25zM21.41 6.34l-3.75-3.75l-2.53 2.54l3.75 3.75l2.53-2.54z"/></svg>
                        </div>
                    </div>
                    <div>
                        <div className="flex">
                            <p className='w-24'>Username :</p>
                            <p>{user?.name}</p>
                        </div>
                        <div className="flex">
                            <p className='w-24'>Email :</p>
                            <p>{user?.email}</p>
                        </div>
                    </div>
                </div>
                <div className="sm:text-right text-center">
                    <button onClick={() => setResetPass(true)} className='bg-indigo-500 hover:bg-indigo-400 text-white py-1 px-4 rounded mt-4 sm:mr-16'>Change Password</button>
                </div>
            </div>)
            )}
        </>
    );
}

export default Account;
