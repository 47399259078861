import React from 'react';
import { Link } from 'react-router-dom';
import congo from '../images/congo.svg';

const ResetDone = () => {
    return (
        <div className='container mx-auto flex-1 h-screen flex flex-col space-y-3 justify-center items-center text-center px-5'>
            <img className='lg:w-1/4 w-52 sm:w-1/3' src={congo} alt="congratulations" />
            <h1 className='font-bold text-2xl mb-4'>Congratulations!</h1>
            <h1>Your password has been changes successfully changed.</h1>
            <Link to="/login" className='text-indigo-600 hover:text-indigo-500'>LOGIN</Link>
        </div>
    );
}

export default ResetDone;
