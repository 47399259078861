import React from 'react';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';

const ViewJob = () => {
    const location = useLocation();
    const stateData = location.state?.data;
    const num = useSelector(state => state.userData.user);
    console.log(num);

    return (
        <div>
            View Job
            {console.log(stateData)}
        </div>
    );
}

export default ViewJob;
