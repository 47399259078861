import React, { useState, useEffect } from 'react';
import ReactPaginate from 'react-paginate';
import EditRole from '../modals/EditRole';
import AddRole from '../modals/AddRole';
import Aos from 'aos';
import 'aos/dist/aos.css';

const Home = () => {

    const data = [
        {
            id: 1,
            name: 'Sultan',
            permissions: [
                'Employee/Resource',
                'Calendar',
                'Jobs Management',
                'Leave Requests',
                'My Account'
            ]
        },
        {
            id: 2,
            name: 'Billah',
            permissions: [
                'Employee/Resource',
                'Calendar',
                'Leave Requests',
                'My Account'
            ]
        },
        {
            id: 3,
            name: 'Rakib',
            permissions: [
                'Employee/Resource',
                'Tasks Management',
                'Leave Requests',
                'My Account'
            ]
        },
        {
            id: 4,
            name: 'Sumon',
            permissions: [
                'Employee/Resource',
                'Leave Requests',
                'My Account'
            ]
        },
    ]
    const [showPermissions, setShowPermissions] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [currentData, setCurrentData] = useState([]);
    const [editRole, setEditRole] = useState(false);
    const [addRole, setAddRole] = useState(false);

    useEffect(() => {
        const itemsPerPage = 10;
        const indexOfLastItem = currentPage * itemsPerPage;
        const indexOfFirstItem = indexOfLastItem - itemsPerPage;
        const temp = data?.slice(indexOfFirstItem, indexOfLastItem)
        setCurrentData(temp);
    }, [currentPage])

    useEffect(() => {
        Aos.init({duration: 500});
    }, [])

    const handleDelete = () => {
        console.log('Delete');
    }

    const handlePageClick = ({selected}) => {
        setCurrentPage(selected + 1)
    };
        
    return (
        <div className='w-full p-4 text-gray-700 bg-white shadow-md rounded-md mt-4'>
            {addRole && <AddRole addRole={() => setAddRole(false)} />}
            {editRole && <EditRole editRole={() => setEditRole(false)} />}
            <div className="sm:flex space-y-2 sm:space-y-0 justify-between items-center">
                <p className='font-bold text-xl'>Employees roles and permission</p>
                <button onClick={() => setAddRole(true)} className='flex items-center gap-2 bg-indigo-600 hover:bg-indigo-500 px-4 py-1 rounded text-white'>
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24"><path fill="currentColor" d="M12 4c4.411 0 8 3.589 8 8s-3.589 8-8 8s-8-3.589-8-8s3.589-8 8-8m0-2C6.477 2 2 6.477 2 12s4.477 10 10 10s10-4.477 10-10S17.523 2 12 2zm5 9h-4V7h-2v4H7v2h4v4h2v-4h4v-2z"/></svg>
                    Add New Role</button>
            </div>
            <div className="mt-4 flex text-sm text-center font-medium text-indigo-600 border leading-10 bg-gray-200">
                <p className='flex-1'>Role ID</p>
                <p className='flex-1'>Role Name</p>
                <p className='flex-1'>Role Permissions</p>
                <p className='flex-1'>Actions</p>
            </div>

            {currentData?.map((item, index) => (
                <div key={index} className="flex text-center text-sm border-b border-l border-r leading-9">
                    <p className='flex-1'>{item.id}</p>
                    <p className='flex-1'>{item.name}</p>
                    <div className="flex-1 relative">
                        <p
                            className="transition-colors duration-300 hover:text-indigo-600"
                            onMouseEnter={() => setShowPermissions(index)}
                            onMouseLeave={() => setShowPermissions(null)}
                        >{`${item.permissions.length} Permissions`}</p>
                        {showPermissions === index && (
                            <div data-aos='fade-left' className="absolute border border-gray-300 text-sm z-10 text-left text-gray-700 px-2 py-1 translate-x-1/2 -mt-1 rounded shadow-lg backdrop-filter backdrop-blur-sm bg-opacity-30">
                                {item.permissions.map((permission, index) => (
                                    <p key={index}>{permission}</p>
                                ))}
                            </div>
                        )}
                    </div>

                    <div className='flex flex-1 gap-4 justify-center items-center'>
                        <svg onClick={() => setEditRole(true)} className='cursor-pointer' xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24"><path fill="#0abb8f" d="M3 17.25V21h3.75L17.81 9.94l-3.75-3.75L3 17.25zM21.41 6.34l-3.75-3.75l-2.53 2.54l3.75 3.75l2.53-2.54z"/></svg>
                        <svg onClick={handleDelete} className='cursor-pointer' xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24"><path fill="#e82d29" d="M19 4h-3.5l-1-1h-5l-1 1H5v2h14M6 19a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V7H6v12Z"/></svg>
                    </div>
                </div>
            ))}
            <ReactPaginate
                breakLabel={'...'}
                pageCount={Math.ceil((data?.length || 0) / 10)}
                pageRangeDisplayed={3}
                onPageChange={handlePageClick}
                previousLabel="Previous"
                nextLabel="Next"
                previousLinkClassName="px-2 py-px rounded-sm bg-blue-500 text-white"
                nextLinkClassName="px-2 py-px rounded-sm bg-blue-500 text-white"
                activeClassName="bg-blue-500 text-white rounded-sm"
                pageLinkClassName="p-2"
                className='flex gap-2 mt-5 justify-end'
            />
        </div>
    );
}

export default Home;
