import { Routes, Route } from "react-router-dom";
import Login from "./components/pages/Login";
// import Register from './components/pages/Register';
import ForgetPass from "./components/pages/ForgetPass";
import ResetLinkSend from "./components/ResetLinkSend";
import ResetPass from "./components/pages/ResetPass";
import ResetDone from "./components/ResetDone";
import Home from "./components/pages/Home";
import PrivateRoutesWithLayout from "./components/PrivateRoutesWithLayout";
import Employee from "./components/pages/Employee";
import CalendarTask from "./components/pages/CalendarTask";
import Jobs from "./components/pages/Jobs";
import Tasks from "./components/pages/Tasks";
import Leave from "./components/pages/Leave";
import Account from "./components/pages/Account";
import ViewJob from "./components/ViewJob";
import useAuth from "./hook/useAuth";
import RPOSchedule from "./components/pages/RPOSchedule";
import RPO from "./components/pages/RPO";
import Operator from "./components/pages/Operator";
import Operate from "./components/Operate";
import RPOPage from "./components/pages/RPOPage";

const App = () => {
  const { loading } = useAuth();

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <Routes>
      <Route path="/login" element={<Login />} />
      {/* <Route path="/register" element={<Register />} /> */}
      <Route path="/forgetpass" element={<ForgetPass />} />
      <Route path="/reset-link-send" element={<ResetLinkSend />} />
      <Route path="/reset-pass" element={<ResetPass />} />
      <Route path="/reset-done" element={<ResetDone />} />

      <Route element={<PrivateRoutesWithLayout />}>
        <Route path="/" element={<Home />} />
        <Route path="/employee-resource" element={<Employee />} />
        <Route path="/calendar" element={<CalendarTask />} />
        <Route path="/jobs-management" element={<Jobs />} />
        <Route path="/tasks-management" element={<Tasks />} />
        <Route path="/leave-requests" element={<Leave />} />
        <Route path="/account" element={<Account />} />
        <Route path="/jobs-management/view-job" element={<ViewJob />} />
        <Route path="/rpo-schedule" element={<RPOSchedule />} />
        <Route path="/rpo" element={<RPO />} />
        <Route path="/rpo/:id" element={<RPOPage />} />
        <Route path="/operator" element={<Operator />} />
        <Route path="/operator/:name" element={<Operate />} />

        {/* {role === "Admin" && <Route path="/register" element={<Register />} />} */}
      </Route>
    </Routes>
  );
};

export default App;
