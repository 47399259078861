import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";

const RPOPage = () => {
  const location = useLocation();
  const dca = location.state;
  const [rpo, setRpo] = useState("");
  const [activeRpo, setActiveRpo] = useState(null);
  const [rpoList, setRpoList] = useState([]);
  const [dataRPO, setDataRPO] = useState([]);
  const [rows, setRows] = useState([]);
  const [row, setRow] = useState(1);

  useEffect(() => {
    if (dca) {
      fetchRPO();
    }
  }, [dca]);

  const fetchRPO = async () => {
    const res = await fetch(
      `https://ampac.atpldhaka.com/api/rpo-details/${dca}`
    );
    const data = await res.json();
    setDataRPO(data.RPOSchedule);
    setRpo(data.RPOSchedule[0]);
    setActiveRpo(data.RPOSchedule[0].RPONo);
    console.log(data.RPOSchedule[0]);
    setRpoList(data.RPOSchedule.map((item) => item.RPONo));
    if (data.RPOSchedule.length === 0 || data.error)
      console.log("No data found");
  };

  const handleRPOChange = (event) => {
    setRpo(dataRPO.find((item) => item.RPONo === event.target.value));
    setActiveRpo(event.target.value);
  };

  const handleRowChange = (index, fieldName, value) => {
    // Update the specific field value of a row
    const updatedRows = rows.map((row, i) =>
      i === index ? { ...row, [fieldName]: value } : row
    );
    setRows(updatedRows);
  };

  const addRow = () => {
    setRows([...rows, {}]); // Add an empty row
  };

  return (
    <div className="w-full mt-4 p-4 rounded-md text-xs text-gray-700 bg-white shadow-md">
      <div className="flex flex-wrap gap-2 leading-6">
        <div className="w-32">
          <p className="font-bold">Part No</p>
          {/* <input className="w-full p-1 border outline-none" value={dcaTemp} onChange={(e) => setDcaTemp(e.target.value)} onKeyDown={(e) => {if(e.key === 'Enter') setDca(e.target.value)}} placeholder="Part No"/> */}
          <p>{dca}</p>
        </div>
        <div className="w-32">
          <label htmlFor="test" className="font-bold">
            Select RPO
          </label>
          <select
            id="test"
            className="outline-none border  rounded-sm px-0.5 w-28"
            value={activeRpo}
            onChange={handleRPOChange}
          >
            {rpoList.map((item) => (
              <option key={item} value={item}>
                {item}
              </option>
            ))}
          </select>
        </div>
        <div className="w-32">
          <p className="font-bold">Ord Qty</p>
          <p className="">{Number(rpo.OrdQty)}</p>
        </div>
        <div className="w-32">
          <p className="font-bold">OutstdQty</p>
          <p className="">{Number(rpo.OutstdQty)}</p>
        </div>
        <div className="w-32">
          <p className="font-bold">Total Allo Time (min)</p>
          <p className="">{Number(rpo.AlloHours)}</p>
        </div>
        <div className="w-32">
          <p className="font-bold">ID</p>
          <p className="">{Number(rpo.id)}</p>
        </div>
        <div className="w-32">
          <p className="font-bold">CRSD</p>
          <p className="">{rpo.CRSD}</p>
        </div>
        <div className="w-32">
          <p className="font-bold">ACSD</p>
          <p className="">{rpo.ACSD}</p>
        </div>
        <div className="w-32">
          <p className="font-bold">Date Completed</p>
          <p className=""></p>
        </div>
        <div className="w-32">
          <p className="font-bold">Actual Time (min)</p>
          <p className=""></p>
        </div>
        <div className="w-32">
          <p className="font-bold">Efficiency</p>
          <p className=""></p>
        </div>
        <div className="w-32">
          <p className="font-bold">IntOrdNo</p>
          <p className="">{rpo.IntOrdNo}</p>
        </div>
        <div className="w-32">
          <p className="font-bold">Supervisor</p>
          <p className=""></p>
        </div>
        <div className="w-32">
          <p className="font-bold">ProdStartDate</p>
          <p className="">{rpo.ProdStartDate}</p>
        </div>
        <div className="w-32">
          <p className="font-bold">EstCompDate</p>
          <p className="">{rpo.EstCompDate}</p>
        </div>
        <div className="w-32">
          <p className="font-bold">Kit Status</p>
          <p className="">{rpo.KitStatus}</p>
        </div>
        <div className="w-32">
          <p className="font-bold">Status</p>
          <p className="">{rpo.StatusFlag}</p>
        </div>
      </div>
      <div className="my-5 overflow-x-auto scroll-efffect-horizontal border">
        <div className="flex justify-between min-w-fit font-bold leading-6">
          <p className="w-8 p-1">No</p>
          <p className="w-20 p-1 ">Process</p>
          <p className="w-24 p-1">Date</p>
          <p className="w-24 p-1">Operator</p>
          <p className="w-28 p-1">Allo Time (min)</p>
          <p className="w-24 p-1">Time Start</p>
          <p className="w-24 p-1">Time End</p>
          <p className="w-20 p-1">Actual Time</p>
          <p className="w-20 p-1">Qty</p>
          <p className="w-24 p-1">Status</p>
          <p className="w-32 p-1">Comments</p>
        </div>
        {/* <div className="flex justify-between min-w-fit"> */}
        {rows.map((row, index) => (
          <div
            key={index}
            className="flex items-center justify-between min-w-fit border-t text-left"
          >
            <p className="w-8 p-1 text-right">{index + 1}</p>
            <div className="w-20 p-1 overflow-y-auto">
              <select
                className="outline-none border w-full rounded-sm p-1 shadow max-h-40"
                value={row.jobType || ""}
                onChange={(e) =>
                  handleRowChange(index, "jobType", e.target.value)
                }
              >
                <option value=""></option>
                <option value="part-time">Kit</option>
                <option value="part-time">Crimp</option>
                <option value="full-time">Cut</option>
                <option value="full-time">Strip</option>
                <option value="full-time">Solder</option>
                <option value="full-time">Assemble</option>
                <option value="full-time">Test</option>
                <option value="full-time">Pack</option>
                <option value="full-time">Custom</option>
                <option value="full-time">Tin</option>
                <option value="full-time">Sample</option>
                <option value="full-time">Test/Pack</option>
                <option value="full-time">Clean</option>
                <option value="full-time">Rework</option>
                <option value="full-time">CNC</option>
              </select>
            </div>
            <div className="w-24 p-1">
              <input
                type="date"
                className="w-full p-1 border shadow rounded-sm"
                onChange={(e) => handleRowChange(index, "date", e.target.value)}
              />
            </div>
            <div className="w-24 p-1">
              <select
                className="outline-none border w-full rounded-sm p-1 shadow max-h-40"
                value={row.operator || ""}
                onChange={(e) =>
                  handleRowChange(index, "operator", e.target.value)
                }
              >
                <option value=""></option>
                <option value="part-time">Imran</option>
                <option value="part-time">Jamil</option>
                <option value="full-time">Habib</option>
                <option value="full-time">Ony</option>
                <option value="full-time">Rahat</option>
                <option value="full-time">Shawon</option>
                <option value="full-time">Safwon</option>
                <option value="full-time">Rahad</option>
                <option value="full-time">Jannatul</option>
              </select>
            </div>
            <div className="w-28 p-1">
              <input
                type="number"
                className="w-full p-1 border shadow rounded-sm"
                onChange={(e) =>
                  handleRowChange(index, "alloTime", e.target.value)
                }
              />
            </div>
            <div className="w-24 p-1">
              <input
                type="date"
                className="w-full p-1 border shadow rounded-sm"
                onChange={(e) =>
                  handleRowChange(index, "timeStart", e.target.value)
                }
              />
            </div>
            <div className="w-24 p-1">
              <input
                type="date"
                className="w-full p-1 border shadow rounded-sm"
                onChange={(e) =>
                  handleRowChange(index, "timeEnd", e.target.value)
                }
              />
            </div>
            <div className="w-20 p-1">
              <input
                type="number"
                className="w-full p-1 border shadow rounded-sm"
                onChange={(e) =>
                  handleRowChange(index, "actualTime", e.target.value)
                }
              />
            </div>
            <div className="w-20 p-1">
              <input
                type="number"
                className="w-full p-1 border shadow rounded-sm"
                onChange={(e) => handleRowChange(index, "qty", e.target.value)}
              />
            </div>
            <div className="w-24 p-1">
              <select
                className="outline-none border w-full rounded-sm p-1 shadow max-h-40"
                value={row.status || ""}
                onChange={(e) =>
                  handleRowChange(index, "status", e.target.value)
                }
              >
                <option value=""></option>
                <option value="part-time">Nine</option>
                <option value="part-time">WIP</option>
                <option value="full-time">Paused</option>
                <option value="full-time">Completed</option>
                <option value="full-time">Cancled</option>
              </select>
            </div>
            <div className="w-32 p-1">
              <input
                type="text"
                className="w-full p-1 border shadow rounded-sm"
                onChange={(e) => handleRowChange(index, "cmnt", e.target.value)}
              />
            </div>
          </div>
        ))}
        {/* </div> */}
      </div>
      <button
        className="bg-teal-400 rounded px-2 py-1 text-white font-semibold hover:bg-teal-500 mt-4"
        onClick={addRow}
      >
        + ADD ROW
      </button>
    </div>
  );
};

export default RPOPage;
