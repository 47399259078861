import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  operators: [{
    id: 0,
    name: 'Jamil',
    work: [
        { RPONo: 'RPO-0001', process: 'Crimp', date: '2023-09-01', allocTime : 45, timeStart: '2023-09-01', timeEnd: '2023-09-02', actualTime: 42, qty: 90, status: "Completed", commments: 'Remarks' },
        { RPONo: 'RPO-0002', process: 'Assemble', date: '2023-09-01', allocTime : 42, timeStart: '2023-09-01', timeEnd: '2023-09-02', actualTime: 40, qty: 190, status: "Completed", commments: 'Remarks' },
        { RPONo: 'RPO-0003', process: 'Cut', date: '2023-09-01', allocTime : 52, timeStart: '2023-09-01', timeEnd: '2023-09-02', actualTime: 52, qty: 20, status: "Completed", commments: '' },
        { RPONo: 'RPO-0004', process: 'Clean', date: '2023-09-01', allocTime : 15, timeStart: '2023-09-01', timeEnd: '2023-09-02', actualTime: 14, qty: 10, status: "Completed", commments: 'Remarks' },
      ]
  },{
    id: 1,
    name: 'Imran',
    work: []
  },{
    id: 2,
    name: 'Habib',
    work: []
  },{
    id: 3,
    name: 'Ony',
    work: []
  },{
    id: 4,
    name: 'Shawon',
    work: []
  },{
    id: 5,
    name: 'Safwon',
    work: []
  },{
    id: 6,
    name: 'Jannatul',
    work: []
  },{
    id: 7,
    name: 'Rahad',
    work: []
  },{
    id: 8,
    name: 'Rahat',
    work: []
  }],
}

export const operatorData = createSlice({
  name: 'operatorData',
  initialState,
})

// Action creators are generated for each case reducer function
export const { } = operatorData.actions

export default operatorData.reducer