import React, { useState } from 'react';

const JobsFilter = ({ filterJob, onFilterChange }) => {

    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [status, setStatus] = useState([]);
    const [parts, setParts] = useState([]);
    const [wireCutting, setWireCutting] = useState([]);
    const [crimping, setCrimping] = useState([]);
    const [csp, setCsp] = useState([]);

    const handleStatus = (value) => {
        if(status.includes(value)){
            setStatus(status.filter((item) => item !== value));
        }else{
            setStatus([...status, value]);
        }
    }

    const handleParts = (value) => {
        if(parts.includes(value)){
            setParts(parts.filter((item) => item !== value));
        }else{
            setParts([...parts, value]);
        }
    }

    const handleWireCutting = (value) => {
        if(wireCutting.includes(value)){
            setWireCutting(wireCutting.filter((item) => item !== value));
        }else{
            setWireCutting([...wireCutting, value]);
        }
    }

    const handleCrimping = (value) => {
        if(crimping.includes(value)){
            setCrimping(crimping.filter((item) => item !== value));
        }else{
            setCrimping([...crimping, value]);
        }
    }

    const handleCSP = (value) => {
        if(csp.includes(value)){
            setCsp(csp.filter((item) => item !== value));
        }else{
            setCsp([...csp, value]);
        }
    }

    const handleApply = () => {
        const count = status.length + parts.length + wireCutting.length + crimping.length + csp.length + (startDate !== '' && endDate !== '' ? 1 : 0);
        onFilterChange(count);
        filterJob();
    }

    return (
        <div className='bg-modal'>
            <div className="modal w-96 md:w-fit">
                <p className='text-xl font-semibold leading-10'>Filter Jobs</p>
                <hr />
                <div className="md:flex gap-4 space-y-2 md:space-y-0 pb-2">
                    <div className='md:w-72'>
                        <p className='font-semibold pb-1'>Start Date</p>
                        <input value={startDate} onChange={(e) => setStartDate(e.target.value)} type="date" className='border w-full outline-none rounded px-2 bg-gray-100'/>
                    </div>
                    <div className='md:w-72'>
                        <p className='font-semibold pb-1'>End Date</p>
                        <input onChange={(e) => setEndDate(e.target.value)} type="date" className='border w-full outline-none rounded px-2 bg-gray-100'/>
                    </div>
                </div>
                <hr />
                <p className='font-semibold'>Status</p>
                <div className='flex flex-wrap md:w-[576px] flex-1 gap-3'>
                    <div>
                        <input checked={status.includes('Not Started')} onChange={() => handleStatus('Not Started')} type="checkbox" /> Not Started
                    </div>
                    <div>
                        <input checked={status.includes('Accepted')} onChange={() => handleStatus('Accepted')} type="checkbox" /> Accepted
                    </div>
                    <div>
                        <input checked={status.includes('Not Scheduled')} onChange={() => handleStatus('Not Scheduled')} type="checkbox" /> Not Scheduled
                    </div>
                    <div>
                        <input checked={status.includes('Scheduled')} onChange={() => handleStatus('Scheduled')} type="checkbox" /> Scheduled
                    </div>
                    <div>
                        <input checked={status.includes('In Production')} onChange={() => handleStatus('In Production')} type="checkbox" /> In Production
                    </div>
                    <div>
                        <input checked={status.includes('Completed')} onChange={() => handleStatus('Completed')} type="checkbox" /> Completed
                    </div>
                    <div>
                        <input checked={status.includes('Partially Completed')} onChange={() => handleStatus('Partially Completed')} type="checkbox" /> Partially Completed
                    </div>
                    <div>
                        <input checked={status.includes('Suspended/Canceled')} onChange={() => handleStatus('Suspended/Canceled')} type="checkbox" /> Suspended/Canceled
                    </div>
                </div>
                <hr />
                <div className='md:flex gap-4'>
                    <div className='md:w-72'>
                        <p className='font-semibold'>Parts</p>
                        <div className='flex gap-4'>
                            <div>
                                <input checked={parts.includes('Yes')} onChange={() => handleParts('Yes')} type="checkbox" /> Yes
                            </div>
                            <div>
                                <input checked={parts.includes('No')} onChange={() => handleParts('No')} type="checkbox" /> No
                            </div>
                            <div>
                                <input checked={parts.includes('Not Applicable')} onChange={() => handleParts('Not Applicable')} type="checkbox" /> Not Applicable
                            </div>
                        </div>
                    </div>
                    <div className='md:w-72'>
                        <p className='font-semibold'>Wire Cutting</p>
                        <div className='flex gap-4'>
                            <div>
                                <input checked={wireCutting.includes('Yes')} onChange={() => handleWireCutting('Yes')} type="checkbox" /> Yes
                            </div>
                            <div>
                                <input checked={wireCutting.includes('No')} onChange={() => handleWireCutting('No')} type="checkbox" /> No
                            </div>
                            <div>
                                <input checked={wireCutting.includes('Not Applicable')} onChange={() => handleWireCutting('Not Applicable')} type="checkbox" /> Not Applicable
                            </div>
                        </div>
                    </div>
                </div>
                <hr />
                <div className='md:flex gap-4'>
                    <div className='md:w-72'>
                        <p className='font-semibold'>Crimping</p>
                        <div className='flex gap-4'>
                            <div>
                                <input checked={crimping.includes('Yes')} onChange={() => handleCrimping('Yes')} type="checkbox" /> Yes
                            </div>
                            <div>
                                <input checked={crimping.includes('No')} onChange={() => handleCrimping('No')} type="checkbox" /> No
                            </div>
                            <div>
                                <input checked={crimping.includes('Not Applicable')} onChange={() => handleCrimping('Not Applicable')} type="checkbox" /> Not Applicable
                            </div>
                        </div>
                    </div>
                    <div>
                        <p className='font-semibold'>CSP</p>
                        <div className='flex gap-4'>
                            <div>
                                <input checked={csp.includes('Yes')} onChange={() => handleCSP('Yes')} type="checkbox" /> Yes
                            </div>
                            <div>
                                <input checked={csp.includes('No')} onChange={() => handleCSP('No')} type="checkbox" /> No
                            </div>
                            <div>
                                <input checked={csp.includes('Not Applicable')} onChange={() => handleCSP('Not Applicable')} type="checkbox" /> Not Applicable
                            </div>
                        </div>
                    </div>
                </div>
                <div className="flex gap-4 text-white pt-2">
                    <button onClick={handleApply} className='flex-1 bg-green-500 hover:bg-green-400 py-1 rounded'>Apply</button>
                    <button onClick={filterJob} className='flex-1 bg-rose-500 hover:bg-rose-400 py-1 rounded'>Cancel</button>
                </div>
            </div>
        </div>
    );
}

export default JobsFilter;
