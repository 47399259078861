import React, { useState } from 'react';

const AddSubtask = ({ addSubtask }) => {
    
    const [title, setTitle] = useState('');
    const [rpo, setRpo] = useState('');
    const [date, setDate] = useState('');
    const [time, setTime] = useState('');
    const [operator, setOperator] = useState('');
    const [allocatedTime, setAllocatedTime] = useState('');
    const [description, setDescription] = useState('');
    const [file, setFile] = useState('');

    const handleSave = () => {
        console.log(title, rpo, date, time, operator, allocatedTime, description, file);
    }

    return (
        <div className='bg-modal pt-14'>
            <div className="modal w-96 md:w-fit">
                <p className='text-xl font-semibold leading-10'>Create Subtask</p>
                <hr />
                <div className="md:flex gap-4 space-y-2 md:space-y-0">
                    <div className='md:w-72'>
                        <p>Title</p>
                        <input onChange={(e) => setTitle(e.target.value)} type="text" className='border w-full outline-none rounded-sm px-2 bg-gray-100'/>
                    </div>
                    <div className='md:w-72'>
                        <p>RPO Number</p>
                        <input onChange={(e) => setRpo(e.target.value)} type="text" className='border w-full outline-none rounded-sm px-2 bg-gray-100'/>
                    </div>
                </div>
                <div className="md:flex gap-4 space-y-2 md:space-y-0">
                    <div className='md:w-72'>
                        <p>Start Date</p>
                        <input onChange={(e) => setDate(e.target.value)} type="date" className='border w-full outline-none rounded-sm px-2 bg-gray-100'/>
                    </div>
                    <div className='md:w-72'>
                        <p>Start Time</p>
                        <input onChange={(e) => setTime(e.target.value)} type="time" className='border w-full outline-none rounded-sm px-2 bg-gray-100'/>
                    </div>
                </div>
                <div className="md:flex gap-4 space-y-2 md:space-y-0">
                    <div className='md:w-72'>
                        <p>Operator Name</p>
                        <input onChange={(e) => setOperator(e.target.value)} type="text" className='border w-full outline-none rounded-sm px-2 bg-gray-100'/>
                    </div>
                    <div className='md:w-72'>
                        <p>Allocated Time</p>
                        <input onChange={(e) => setAllocatedTime(e.target.value)} type="number" className='border w-full outline-none rounded-sm px-2 bg-gray-100'/>
                    </div>
                </div>
                <div className="md:flex gap-4 space-y-2 md:space-y-0">
                    <div className='md:w-72'>
                        <p>Description</p>
                        <textarea onChange={(e) => setDescription(e.target.value)} className='border w-full outline-none rounded-sm px-2 bg-gray-100'/>
                    </div>
                    <div className='md:w-72'>
                        <p>Upload Instruction File</p>
                        <div className="bg-gray-100 border-2 border-dashed border-gray-300 rounded-sm px-2 h-16">
                            <input hidden id='upload' onChange={(e) => setFile(e.target.value)} type="file" className='w-full'/>
                            <label htmlFor="upload" className='text-center'>
                                <svg className="m-auto" xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 24 24">
                                    <path fill="rgb(38,166,154)" d="M19.35 10.04A7.49 7.49 0 0 0 12 4C9.11 4 6.6 5.64 5.35 8.04A5.994 5.994 0 0 0 0 14c0 3.31 2.69 6 6 6h13c2.76 0 5-2.24 5-5c0-2.64-2.05-4.78-4.65-4.96zM14 13v4h-4v-4H7l5-5l5 5h-3z"/>
                                </svg>
                                <p className="text-sm">Click To Upload</p>
                            </label>
                        </div>
                    </div>
                </div>
                <div className="flex gap-4 text-white pt-2">
                    <button onClick={handleSave} className='flex-1 bg-green-500 hover:bg-green-400 py-1 rounded'>Save</button>
                    <button onClick={addSubtask} className='flex-1 bg-rose-500 hover:bg-rose-400 py-1 rounded'>Cancel</button>
                </div>
            </div>
        </div>
    );
}

export default AddSubtask;
