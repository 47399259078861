import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { setPicture } from "../../redux/user";

const PropicUpload = ({ editPicture }) => {
  const dispatch = useDispatch();
  const [file, setFile] = useState(null);
  const [tempFile, setTempFile] = useState(null);
  const proPic = useSelector(
    (state) => state.userData.user.profile.profile_picture
  );

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setTempFile(reader.result);
      };
      reader.readAsDataURL(selectedFile);
    } else {
      setTempFile(null);
    }
  };

  const handleUpload = () => {
    const formData = new FormData();
    formData.append(`image`, file[0]);

    fetch("https://ampac.atpldhaka.com/api/upload-profile-picture", {
      method: "POST",
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
      body: formData,
    })
      .then((res) => res.json())
      .then((data) => {
        dispatch(setPicture(data.image_url));
        editPicture();
      })
      .catch((err) => console.log(err));
  };

  return (
    <div className="bg-modal">
      <div className="modal w-80 md:w-96">
        <p className="text-xl font-semibold leading-10">Change Image</p>
        <hr />
        <div className="flex itmes-center justify-center gap-6 py-6">
          <div className="h-24 w-24 overflow-hidden rounded-full">
            <img
              className="h-full w-full object-cover"
              src={tempFile || proPic}
              alt="profile pic"
            />
          </div>
          <div className="bg-gray-100 border-2 border-dashed border-gray-300 rounded-sm px-2 md:w-60 w-40 flex items-center justify-center">
            {/* <input id="upload" hidden onChange={handleFileChange} type="file" className='w-full'/> */}
            <input
              id="upload"
              hidden
              type="file"
              onChange={(e) => {
                setFile(e.target.files);
                handleFileChange(e);
              }}
              className="w-full"
            />
            <label htmlFor="upload" className="text-center">
              <svg
                className="m-auto"
                xmlns="http://www.w3.org/2000/svg"
                width="32"
                height="32"
                viewBox="0 0 24 24"
              >
                <path
                  fill="rgb(38,166,154)"
                  d="M19.35 10.04A7.49 7.49 0 0 0 12 4C9.11 4 6.6 5.64 5.35 8.04A5.994 5.994 0 0 0 0 14c0 3.31 2.69 6 6 6h13c2.76 0 5-2.24 5-5c0-2.64-2.05-4.78-4.65-4.96zM14 13v4h-4v-4H7l5-5l5 5h-3z"
                />
              </svg>
              <p className="text-sm">Click To Upload</p>
            </label>
          </div>
        </div>
        <div className="flex gap-4 text-white pt-2">
          <button
            onClick={handleUpload}
            className="flex-1 bg-green-500 hover:bg-green-400 py-1 rounded"
          >
            Upload
          </button>
          <button
            onClick={editPicture}
            className="flex-1 bg-rose-500 hover:bg-rose-400 py-1 rounded"
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
};

export default PropicUpload;
