import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

export default function Operator() {
  const test = useSelector((state) => state.operatorData.operators);
  const [data, setData] = useState([]);

  useEffect(() => {
    const token = localStorage.getItem("token");

    const fetchData = async () => {
      try {
        const response = await fetch(
          "https://ampac.atpldhaka.com/api/operator",
          {
            headers: {
              Accept: "application/json",
              Authorization: "Bearer " + token,
            },
          }
        );
        const data = await response.json();
        setData(data);
        // setLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        // setLoading(false);
      }
    };

    // fetchData();
  }, []);

  return (
    <div className="w-full mt-4 p-4 rounded-md text-gray-700 bg-white shadow-md">
      <p className="text-xl font-semibold leading-10 mb-2 text-center">
        Operator List
      </p>
      <div className="border leading-8">
        <p className="text-center font-bold bg-gray-100">
          List of the Operator
        </p>
        {test?.map((item) => (
          <Link
            key={Math.random()}
            to={`${item.name}`}
            className="border-t px-4 block hover:shadow-md hover:bg-lime-50"
          >
            {item.name}
          </Link>
        ))}
      </div>
    </div>
  );
}
