import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setUser } from "../redux/user";

const useAuth = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    let isMounted = true; // To track whether the component is still mounted
    const token = localStorage.getItem("token");
    if (token) {
      fetch("https://ampac.atpldhaka.com/api/profile", {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
      })
        .then((res) => {
          if (!res.ok) {
            navigate("/login");
            throw new Error("Network response was not ok");
          }
          return res.json();
        })
        .then((data) => {
          dispatch(setUser(data));
          if (isMounted) {
            setLoading(false);
            navigate("/");
          }
        })
        .catch((err) => {
          if (isMounted) {
            setLoading(false);
          }
          navigate("/login");
        });
    } else {
      setLoading(false);
      navigate("/login");
    }

    return () => {
      isMounted = false; // Cleanup function: Mark the component as unmounted when it's being removed.
    };
  }, []);

  return { loading };
};

export default useAuth;
