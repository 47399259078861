import React, { useState } from 'react';

const ResetPassword = ({ passCom }) => {

    const [pass, setPass] = useState('');
    const [newPass, setNewPass] = useState('');
    const [confirmPass, setConfirmPass] = useState('');

    const handleUpdate = () => {
        console.log(pass, newPass, confirmPass)
    }

    return (
        <div className='w-full mt-4 p-4 rounded-md text-gray-700 bg-white shadow-md'>
            <h2 className='text-xl font-semibold'>My Account</h2>
            <p className='border-b text-lg font-medium my-4 pb-4'>Change Password</p>
            <div className="flex flex-wrap gap-4">
                <div>
                    <p>Current Password</p>
                    <input onChange={e => setPass(e.target.value)}  className='bg-gray-100 border outline-none p-1 w-80 mt-2' type="text" placeholder='Enter password'/>
                </div>
                <div>
                    <p>New Password</p>
                    <input onChange={e => setNewPass(e.target.value)} className='bg-gray-100 border outline-none p-1 w-80 mt-2' type="password" placeholder='Enter password'/>
                </div>
                <div>
                    <p>Confirm Password</p>
                    <input onChange={e => setConfirmPass(e.target.value)} className='bg-gray-100 border outline-none p-1 w-80 mt-2' type="password" placeholder='Enter password'/>
                </div>
            </div>
            <div className="flex gap-4 mt-4">
            <button onClick={handleUpdate} className='bg-indigo-500 hover:bg-indigo-400 text-white rounded px-8 font-semibold py-1'>Update</button>
            <button onClick={passCom} className='bg-rose-500 hover:bg-rose-400 text-white rounded px-8 font-semibold py-1'>Cancel</button>
            </div>
        </div>
    );
}

export default ResetPassword;
