import React from 'react';

const ManageProductionHour = ({ open }) => {

    const handleSave = () => {
        open();
    }
    return (
        <div className='bg-modal'>
            <div className="modal w-80 sm:w-96">
                <p className='text-xl font-semibold leading-10'>Manage Production Hour</p>
                <hr />
                
                <div className="flex gap-4 text-white pt-2">
                    <button onClick={handleSave} className='flex-1 bg-green-500 hover:bg-green-400 py-1 rounded'>Save</button>
                    <button onClick={open} className='flex-1 bg-rose-500 hover:bg-rose-400 py-1 rounded'>Cancel</button>
                </div>
            </div>
        </div>
    );
}

export default ManageProductionHour;
