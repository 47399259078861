import React, { useState, useEffect } from "react";
import { DataGrid } from "@mui/x-data-grid";

export default function RPOSchedule() {
  const [data, setData] = useState([]);

  const columns = [
    {
      field: "RPONo",
      headerName: "RPO Number",
      width: 100,
      style: { fontSize: "10px" },
    },
    { field: "partNo", headerName: "Part No", width: 90 },
    { field: "so_no", headerName: "IntOrdNo", width: 100 },
    { field: "alloHours", headerName: "AlloHours", width: 80, type: "number" },
    {
      field: "prodStartDate",
      headerName: "ProdStartDate",
      width: 110,
      type: "date",
      valueFormatter: (params) =>
        params.value ? params.value.split("T")[0] : null,
    },
    {
      field: "estCompDate",
      headerName: "EstCompDate",
      width: 100,
      type: "date",
      valueFormatter: (params) =>
        params.value ? params.value.split(" ")[0] : null,
    },
    { field: "keyOperator", headerName: "KeyOperator", width: 100 }, //need to edit for selection
    { field: "kitStatus", headerName: "KitStatus", width: 100 },
    { field: "compPct", headerName: "CompPct", width: 90, type: "number" },
    {
      field: "remainHours",
      headerName: "RemainHours",
      width: 100,
      type: "number",
    },
    { field: "statusFlag", headerName: "StatusFlag", width: 90 }, //need to edit for selection
    { field: "hoursUsed", headerName: "HoursUsed", width: 90, type: "number" },
    {
      field: "hoursGainLoss",
      headerName: "HoursGainLoss",
      width: 110,
      type: "number",
    },
    { field: "name", headerName: "CustName", width: 100 },
    { field: "external_doc_no", headerName: "CustPO", width: 90 },
    { field: "quantity", headerName: "OrdQty", width: 80, type: "number" },
    { field: "outStdQty", headerName: "OutstdQty", width: 90, type: "number" },
    { field: "qtyShpd", headerName: "QtyShpd", width: 100, type: "number" },
    {
      field: "request_delivery_date",
      headerName: "CRSD",
      width: 100,
      type: "date",
      valueFormatter: (params) =>
        params.value ? params.value.split(" ")[0] : null,
    },
    {
      field: "acsd",
      headerName: "ACSD",
      width: 100,
      type: "date",
      valueFormatter: (params) =>
        params.value ? params.value.split(" ")[0] : null,
    },
    { field: "custPartNo", headerName: "CustPartNo", width: 90 },
    {
      field: "so_created_at",
      headerName: "OrdDate",
      width: 100,
      type: "date",
      valueFormatter: (params) =>
        params.value ? params.value.split(" ")[0] : null,
    },
    {
      field: "rpo_created_at",
      headerName: "CreateDate",
      width: 100,
      type: "date",
      valueFormatter: (params) =>
        params.value ? params.value.split(" ")[0] : null,
    },
    { field: "rqHrs", headerName: "RqHrs", width: 80, type: "number" },
    {
      field: "remainHour1",
      headerName: "RemainHours1",
      width: 110,
      type: "number",
    },
    { field: "customer_no", headerName: "CustNo", width: 90 },
  ];

  useEffect(() => {
    const token = localStorage.getItem("token");

    const fetchData = async () => {
      try {
        const response = await fetch("https://ampac.atpldhaka.com/api/rpo", {
          headers: {
            Accept: "application/json",
            Authorization: "Bearer " + token,
          },
        });
        const data = await response.json();
        if (data.error) {
          console.log("Error:", data.error);
          // Handle the error condition if needed
        } else {
          setData(data);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        // setLoading(false);
      }
    };

    fetchData();
  }, []);

  const gridStyle = {
    fontSize: "14px", // Set the desired font size here
  };

  return (
    <div className="w-full mt-4 p-4 rounded-md text-gray-700 bg-white shadow-md">
      <p className="text-xl font-semibold leading-10 mb-6">RPO Schedule</p>
      {data && (
        <div className="w-full">
          <DataGrid
            rows={data} // Pass the data array directly to the rows prop
            columns={columns}
            getRowId={(row) => row.RPONo}
            style={gridStyle}
            initialState={{
              pagination: {
                paginationModel: { page: 0, pageSize: 5 },
              },
            }}
            pageSizeOptions={[5, 10]}
          />
        </div>
      )}
    </div>
  );
}
