import React, { useState } from "react";

const AddEmployee = ({ addEmployee, fetchCall }) => {
  const [name, setName] = useState("");
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [password, setPassword] = useState("");
  const [jobType, setJobType] = useState("");
  const [qualification, setQualification] = useState("");

  const handleSave = async () => {
    const token = localStorage.getItem("token");
    const formdata = new FormData();
    formdata.append("full_name", name);
    formdata.append("name", username);
    formdata.append("mobile_number", phoneNumber);
    formdata.append("job_types", jobType);
    formdata.append("qualification", qualification);
    formdata.append("email", email);
    formdata.append("password", password);

    try {
      const response = await fetch(
        "https://ampac.atpldhaka.com/api/employee-resource/create",
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            Authorization: token.slice(token.indexOf("|") + 1),
          },
          body: formdata,
        }
      );
      if (response.ok) {
        fetchCall();
        addEmployee();
      }
      // setLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      // setLoading(false);
    }
  };

  return (
    <div className="bg-modal">
      <div className="modal w-96 md:w-fit">
        <p className="text-xl font-semibold leading-10">Create New Employee</p>
        <hr />
        <div className="md:flex gap-4 space-y-2 md:space-y-0">
          <div className="md:w-72">
            <p>Employee Name</p>
            <input
              onChange={(e) => setName(e.target.value)}
              type="text"
              className="border w-full outline-none rounded-sm px-2 bg-gray-100"
            />
          </div>
          <div className="md:w-72">
            <p>Username</p>
            <input
              onChange={(e) => setUsername(e.target.value)}
              type="text"
              className="border w-full outline-none rounded-sm px-2 bg-gray-100"
            />
          </div>
        </div>
        <div className="md:flex gap-4 space-y-2 md:space-y-0">
          <div className="md:w-72">
            <p>Email</p>
            <input
              onChange={(e) => setEmail(e.target.value)}
              type="email"
              className="border w-full outline-none rounded-sm px-2 bg-gray-100"
            />
          </div>
          <div className="md:w-72">
            <p>Phone Number</p>
            <input
              onChange={(e) => setPhoneNumber(e.target.value)}
              type="number"
              className="border w-full outline-none rounded-sm px-2 bg-gray-100"
            />
          </div>
        </div>
        <div className="md:flex gap-4 space-y-2 md:space-y-0">
          <div className="md:w-72">
            <p>Create Password</p>
            <input
              onChange={(e) => setPassword(e.target.value)}
              type="password"
              className="border w-full outline-none rounded-sm px-2 bg-gray-100"
            />
          </div>
          <div className="md:w-72">
            <p>Selected job types</p>
            <select
              className="outline-none border w-full rounded-sm bg-gray-100 px-1"
              value={jobType}
              onChange={(e) => setJobType(e.target.value)}
            >
              <option value="">Select Job Type</option>
              <option value="part-time">Part Time</option>
              <option value="full-time">Full Time</option>
            </select>
          </div>
        </div>
        <div className="md:w-72">
          <p>Qualification</p>
          <input
            onChange={(e) => setQualification(e.target.value)}
            type="text"
            className="border w-full outline-none rounded-sm px-2 bg-gray-100"
          />
        </div>
        <div className="flex gap-4 text-white pt-2">
          <button
            onClick={handleSave}
            className="flex-1 bg-green-500 hover:bg-green-400 py-1 rounded"
          >
            Save
          </button>
          <button
            onClick={addEmployee}
            className="flex-1 bg-rose-500 hover:bg-rose-400 py-1 rounded"
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
};

export default AddEmployee;
