import React, { useState, useEffect } from 'react';
import ReactPaginate from 'react-paginate';

const Leave = () => {

    const [currentPage, setCurrentPage] = useState(1);
    const [currentData, setCurrentData] = useState([]);
    const [data, setData] = useState([
        {
            id: 1,
            name: 'Sultan Mahmud',
            sickLeave: 'Sick',
            startDate: '10/03/23',
            endDate: '10/03/23',
            startTime: '8:00 AM',
            endTime: '5:00 PM',
            description: 'lorem ipsum dolor sit amet lorem ipsum dolor sit amet lorem ipsum dolor sit',
        },
        {
            id: 2,
            name: 'Mark E Romanowski',
            sickLeave: 'Casual',
            startDate: '10/03/23',
            endDate: '10/03/23',
            startTime: '8:00 AM',
            endTime: '5:00 PM',
            description: 'lorem ipsum dolor sit amet lorem ipsum dolor sit amet lorem ipsum dolor sit',
        },
        {
            id: 3,
            name: 'Barbara B. Morales',
            sickLeave: 'Sick',
            startDate: '10/03/23',
            endDate: '10/03/23',
            startTime: '8:00 AM',
            endTime: '5:00 PM',
            description: 'lorem ipsum dolor sit amet lorem ipsum dolor sit amet lorem ipsum dolor sit',
        },
    ]);

    useEffect(() => {
        const itemsPerPage = 10;
        const indexOfLastItem = currentPage * itemsPerPage;
        const indexOfFirstItem = indexOfLastItem - itemsPerPage;
        setCurrentData(data.slice(indexOfFirstItem, indexOfLastItem));
    }, [currentPage, data]);

    const handlePageClick = ({selected}) => {
        setCurrentPage(selected + 1)
    };

    return (
        <div className='w-full mt-4 p-4 rounded-md text-gray-700 bg-white shadow-md'>
            <h2 className='text-xl font-semibold'>Leave Requests</h2>
            <div className="scroll-efffect-horizontal overflow-x-auto mt-4 text-[12px]">
                <div className="flex justify-between text-indigo-600 leading-6 py-2 border-x bg-gray-100 min-w-[872px] px-2"> 
                    <p className='w-28'>Employee Name</p>
                    <p className='w-16'>Sick Leave</p>
                    <p className='w-16'>Start Date</p>
                    <p className='w-16'>End Date</p>
                    <p className='w-16'>Start Time</p>
                    <p className='w-16'>End Time</p>
                    <p className='w-52'>Description</p>
                    <p className='w-40 text-center'>Actions</p>
                </div>
                <div className=" leading-9 min-w-[872px]">
                {currentData?.map((item) => (
                    <div key={item.id} className="flex items-center border-x justify-between border-b border-gray-100 leading-6 px-2">
                        <p className='w-28'>{item.name}</p>
                        <p className='w-16'>{item.sickLeave}</p>
                        <p className='w-16'>{item.startDate}</p>
                        <p className='w-16'>{item.endDate}</p>
                        <p className='w-16'>{item.startTime}</p>
                        <p className='w-16'>{item.endTime}</p>
                        <p className='w-52'>{item.description}</p>
                        <div className='w-40 flex justify-between px-2'>
                            <button className='w-16 text-white bg-indigo-500 rounded hover:bg-indigo-400'>Accept</button>
                            <button className='w-16 text-white bg-rose-500 rounded hover:bg-rose-400'>Reject</button>
                        </div>
                    </div>
                ))}
                </div>
            </div>
            <ReactPaginate
                breakLabel={'...'}
                pageCount={Math.ceil((data?.length || 0) / 10)}
                pageRangeDisplayed={3}
                onPageChange={handlePageClick}
                previousLabel="Previous"
                nextLabel="Next"
                previousLinkClassName="px-2 py-px rounded-sm bg-blue-500 text-white"
                nextLinkClassName="px-2 py-px rounded-sm bg-blue-500 text-white"
                activeClassName="bg-blue-500 text-white rounded-sm"
                pageLinkClassName="p-2"
                className='flex gap-2 mt-5 justify-end'
            />
        </div>
    );
}

export default Leave;
