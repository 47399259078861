import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import logo from '../../images/logo.png';

const ForgetPass = () => {

  const navigate = useNavigate();
    const [email, setEmail] = useState('');

    const handleSubmit = (e) => {
        e.preventDefault();
        console.log(email);

        if(email === 'imran'){
            navigate('/reset-link-send');
        }
    }

    return (
        <div className="flex min-h-screen flex-1 flex-col justify-center sm:px-6 py-12 lg:px-8">
      <div className="shadow-lg mx-auto px-4 py-6 rounded sm:w-96 w-10/12">
        <div className="sm:mx-auto sm:w-full sm:max-w-sm">
          <img
            className="mx-auto h-10 w-auto"
            src={logo}
            alt="Your Company logo"
          />
          <h2 className="mt-6 text-center text-2xl font-bold leading-4 tracking-tight text-gray-900">
            Forget Password?
          </h2>
        </div>

        <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm px-4">
          <div className="space-y-4">
            <div>
              <label htmlFor="email" className="block text-sm font-medium leading-4 text-gray-900">
                Email address <span className='text-rose-400'>*</span>
              </label>
              <div className="mt-2">
                <input
                  onChange={(e) => setEmail(e.target.value)}
                  type="email"
                  autoComplete="email"
                  required
                  className='border-b w-full outline-none pb-1 px-2 focus:border-indigo-600 mb-4'
                />
              </div>
            </div>

            <button
              onClick={handleSubmit}
              className="w-full mt-4 rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500"
            >
              Send reset link
            </button>
          </div>

          <p className="mt-6 text-center text-sm text-gray-500">
            Back to {' '}
            <Link to="/login" className="font-semibold leading-6 text-indigo-600 hover:text-indigo-500">
              Login
            </Link>
          </p>
        </div>
      </div>
    </div>
    );
}

export default ForgetPass;
