import React, { useEffect, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { useNavigate } from 'react-router-dom'

const Operate = () => {
  const [operator, setOperator] = useState("");
  const [data, setData] = useState([]);
  const [nameList, setNameList] = useState([]);
  const location = useLocation();
  const operate = location.pathname.split("/")[2];
  const operatorData = useSelector((state) => state.operatorData.operators);
  const name = useSelector((state) => state.operatorData.name);
  const [age, setAge] = useState('');
  const navigate = useNavigate();
  
  useEffect(() => {
    setOperator(name);
  }, [name]);

  useEffect(() => {
    const temp = operatorData.filter((item) => item.name === operate);
    setData(temp[0]?.work);
    const tempList = operatorData.map((item) => item.name);
    setNameList(tempList);
  }, [operatorData, operate]);

  const columns = [
    { field: "RPONo", headerName: "RPO Number", width: 100 },
    { field: "process", headerName: "Peocess", width: 100 },
    {
      field: "date",
      headerName: "Date",
      width: 110,
      type: "date",
      valueFormatter: (params) =>
        params.value ? params.value.split("T")[0] : null,
    },
    { field: "allocTime", headerName: "Allo Time", width: 120, type: "number" },
    {
      field: "timeStart",
      headerName: "Time Start",
      width: 110,
      type: "date",
      valueFormatter: (params) =>
        params.value ? params.value.split("T")[0] : null,
    },
    {
      field: "timeEnd",
      headerName: "Time End",
      width: 110,
      type: "date",
      valueFormatter: (params) =>
        params.value ? params.value.split("T")[0] : null,
    },
    {
      field: "actualTime",
      headerName: "Actual Time",
      width: 110,
      type: "number",
    },
    { field: "qty", headerName: "Qty", width: 80, type: "number" },
    { field: "status", headerName: "Status", width: 110 },
    { field: "commments", headerName: "Comments", width: 110 },
  ];

  const gridStyle = {
    fontSize: "14px", // Set the desired font size here
  };

  const handleChange = (event) => {
    setOperator(event.target.value);
  };

  return (
    <div className="w-full mt-4 p-4 rounded-md text-gray-700 bg-white shadow-md">
      <button className="rounded-full bg-gray-200 px-4 py-1 mb-2" onClick={() => navigate(-1)}>Go Back</button>
      <Box sx={{ maxWidth: 120 }}>
        <FormControl fullWidth size="small">
          <InputLabel id="demo-simple-select-label">{`Operator`}</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={operator}
            label="Operator"
            onChange={handleChange}
            sx={{ height: '40px', padding: '0px', margin: '0px'  }}
          >
            {nameList?.map((item, index) => (
              <MenuItem key={index} value={item}>{item}</MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>

      {data.length > 0 && (
        <div className="mt-4">
          <DataGrid
            rows={data} // Pass the data array directly to the rows prop
            columns={columns}
            getRowId={(row) => row.RPONo}
            style={gridStyle}
            initialState={{
              pagination: {
                paginationModel: { page: 0, pageSize: 5 },
              },
            }}
            pageSizeOptions={[5, 10]}
          />
        </div>
      )}
    </div>
  );
};

export default Operate;
