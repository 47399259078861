import React from "react";
// import profile from '../images/profile.png';
import logo from "../images/logo.png";
import { useSelector } from "react-redux";

const Navbar = ({ toggleOpen, open }) => {
  const user = useSelector((state) => state.userData.user);

  return (
    <div className="w-full h-14 bg-white shadow flex items-center justify-between gap-5 px-10">
      <div className="flex items-center gap-5">
        <div
          onClick={toggleOpen}
          className="cursor-pointer text-gray-600 text-bold relative"
        >
          {!open ? (
            <div className={`mt-px transition-opacity duration-300`}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="20"
                viewBox="0 0 24 24"
              >
                <path
                  fill="currentColor"
                  d="M2 6a1 1 0 0 1 1-1h18a1 1 0 1 1 0 2H3a1 1 0 0 1-1-1Zm0 6.032a1 1 0 0 1 1-1h18a1 1 0 1 1 0 2H3a1 1 0 0 1-1-1Zm1 5.032a1 1 0 1 0 0 2h18a1 1 0 0 0 0-2H3Z"
                />
              </svg>
            </div>
          ) : (
            <div className={`-mt-px transition-opacity duration-300`}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 16 16"
              >
                <path
                  fill="none"
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="1.5"
                  d="m11.25 4.75l-6.5 6.5m0-6.5l6.5 6.5"
                />
              </svg>
            </div>
          )}
        </div>
        <img className="w-24" src={logo} alt="logo" />
      </div>
      <div className="flex items-center justify-between gap-5">
        <div className="relative flex h-8 w-8 items-center justify-center rounded-full border-[0.5px] bg-gray-100 hover:text-indigo-600">
          <div className="absolute -top-2 -right-0.5 z-1 h-2 w-2 rounded-full bg-meta-1 inline">
            <span className="animate-ping absolute top-[10px] inline-flex h-full w-full rounded-full bg-indigo-500 opacity-75"></span>
            <span className="relative inline-flex rounded-full h-2 w-2 bg-indigo-600"></span>
          </div>
          <svg
            className="fill-current duration-300 ease-in-out"
            width="18"
            height="18"
            viewBox="0 0 18 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M16.1999 14.9343L15.6374 14.0624C15.5249 13.8937 15.4687 13.7249 15.4687 13.528V7.67803C15.4687 6.01865 14.7655 4.47178 13.4718 3.31865C12.4312 2.39053 11.0812 1.7999 9.64678 1.6874V1.1249C9.64678 0.787402 9.36553 0.478027 8.9999 0.478027C8.6624 0.478027 8.35303 0.759277 8.35303 1.1249V1.65928C8.29678 1.65928 8.24053 1.65928 8.18428 1.6874C4.92178 2.05303 2.4749 4.66865 2.4749 7.79053V13.528C2.44678 13.8093 2.39053 13.9499 2.33428 14.0343L1.7999 14.9343C1.63115 15.2155 1.63115 15.553 1.7999 15.8343C1.96865 16.0874 2.2499 16.2562 2.55928 16.2562H8.38115V16.8749C8.38115 17.2124 8.6624 17.5218 9.02803 17.5218C9.36553 17.5218 9.6749 17.2405 9.6749 16.8749V16.2562H15.4687C15.778 16.2562 16.0593 16.0874 16.228 15.8343C16.3968 15.553 16.3968 15.2155 16.1999 14.9343ZM3.23428 14.9905L3.43115 14.653C3.5999 14.3718 3.68428 14.0343 3.74053 13.6405V7.79053C3.74053 5.31553 5.70928 3.23428 8.3249 2.95303C9.92803 2.78428 11.503 3.2624 12.6562 4.2749C13.6687 5.1749 14.2312 6.38428 14.2312 7.67803V13.528C14.2312 13.9499 14.3437 14.3437 14.5968 14.7374L14.7655 14.9905H3.23428Z"
              fill=""
            ></path>
          </svg>
        </div>

        <div className="flex items-center gap-4">
          <span className="hidden text-right sm:block">
            <span className="block text-sm font-medium text-black font-Courgette">
              {user?.profile.full_name}
            </span>
            <span className="block text-xs text-indigo-600 font-bold">
              {user?.roles}
            </span>
          </span>
          <span className="h-10 w-10 rounded-full overflow-hidden">
            <img
              className="w-full h-full object-cover"
              src={user?.profile.profile_picture}
              alt="User"
            />
          </span>
          <svg
            className="fill-gray-600"
            width="12"
            height="8"
            viewBox="0 0 12 8"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M0.410765 0.910734C0.736202 0.585297 1.26384 0.585297 1.58928 0.910734L6.00002 5.32148L10.4108 0.910734C10.7362 0.585297 11.2638 0.585297 11.5893 0.910734C11.9147 1.23617 11.9147 1.76381 11.5893 2.08924L6.58928 7.08924C6.26384 7.41468 5.7362 7.41468 5.41077 7.08924L0.410765 2.08924C0.0853277 1.76381 0.0853277 1.23617 0.410765 0.910734Z"
              fill=""
            ></path>
          </svg>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
