import React, { useState, useEffect } from "react";

const EditEmployee = ({ editEmployee, editId, fetchCall, name }) => {
  const [searchText, setSearchText] = useState([null, ""]);
  const [suggestions, setSuggestions] = useState([]);
  const [selectedNames, setSelectedNames] = useState([]);
  const [employeeList, setEmployeeList] = useState([]);
  const [assign, setAssign] = useState([]);
  const token = localStorage.getItem("token");

  useEffect(() => {
    fetchEmployeeList();
    fetchAssign();
  }, []);

  const fetchEmployeeList = async () => {
    try {
      const response = await fetch(
        "https://ampac.atpldhaka.com/api/employees",
        {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      );
      const data = await response.json();
      setEmployeeList(data.employees);
      // setLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      // setLoading(false);
    }
  };

  const fetchAssign = async () => {
    const response = await fetch(
      `https://ampac.atpldhaka.com/api/employees/${editId}/assigned`,
      {
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    );
    const data = await response.json();
    setAssign(data.assigned_users);
  };

  const handleRemoveEmployee = (user) => {
    const params = new URLSearchParams();
    params.append("user_id_2", user.id);

    fetch(`https://ampac.atpldhaka.com/api/employees/${editId}/assigned`, {
      method: "DELETE",
      headers: {
        Authorization: "Bearer " + token,
      },
      body: params,
    })
      .then((response) => response.json())
      .then((data) => {
        fetchCall();
        fetchAssign();
      })
      .catch((error) => {
        console.error("Error deleting data:", error);
      });
  };

  const handleSave = () => {
    const token = localStorage.getItem("token");
    const response = fetch(
      `https://ampac.atpldhaka.com/api/employee-resource/${editId}`,
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          Authorization: token.slice(token.indexOf("|") + 1),
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          user_id_2: searchText[0],
        }),
      }
    )
      .then((response) => response.json())
      .then((data) => {
        fetchCall();
        editEmployee();
      });
  };

  const handleInputChange = (event) => {
    const inputValue = event.target.value;
    setSearchText([null, inputValue]);

    // Filter the employee list based on the input value
    const filteredSuggestions = Object.entries(employeeList).filter(
      ([id, employee]) =>
        employee.toLowerCase().indexOf(inputValue.toLowerCase()) !== -1 &&
        editId !== Number(id)
    );
    setSuggestions(filteredSuggestions);
  };

  const handleSuggestionClick = (selectedName) => {
    setSearchText(selectedName);
    setSuggestions([]); // Clear the suggestion list after selecting a name
  };

  const handleCancelClick = (name) => {
    // Remove the name from the selectedNames array
    setSelectedNames(
      selectedNames.filter((selectedName) => selectedName !== name)
    );
  };

  return (
    <div className="bg-modal">
      <div className="modal w-80 sm:w-96">
        <p className="text-xl font-semibold leading-10">
          Edit Reporting Employee
        </p>
        <hr />
        <p className="font-semibold">Reporting employees for {name}</p>
        <div className="flex flex-wrap gap-2 py-3">
          {assign.map((user) => (
            <div
              className=" border border-teal-400 shadow px-2 rounded flex gap-4 items-center"
              key={user.id}
            >
              <div>{user.full_name}</div>
              <button
                onClick={() => handleRemoveEmployee(user)}
                className="text-rose-400"
              >
                x
              </button>
            </div>
          ))}
          {/* {assign && assign.map((employee, index) => (
            <div key={index} className="flex items-center">
              <p className='text-sm font-semibold'>{employee}</p>
            </div>
          ))} */}
        </div>
        <div className="relative">
          {/* Input field to type the employee name */}
          <input
            type="text"
            value={searchText[1]}
            onChange={handleInputChange}
            placeholder="Type employee name"
            className="border px-2 py-1"
            onFocus={handleInputChange}
          />

          {/* Absolute positioned suggestion list */}
          {suggestions.length > 0 && (
            <div className="absolute left-0 mt-1 w-48 bg-white border rounded shadow">
              <ul className="max-h-40 overflow-y-auto">
                {suggestions.map((employee, index) => (
                  <li
                    key={index}
                    className="px-2 py-1 hover:bg-gray-200 cursor-pointer border-b"
                    onClick={() => handleSuggestionClick(employee)} // Add onClick event handler
                  >
                    {employee[1]}
                  </li>
                ))}
              </ul>
            </div>
          )}

          {/* Render selected names with cancel icons */}
          {selectedNames.length > 0 && (
            <div className="absolute left-0 mt-8 w-48 bg-white border rounded shadow">
              <ul className="max-h-40 overflow-y-auto">
                {selectedNames.map((name, index) => (
                  <li
                    key={index}
                    className="px-2 py-1 hover:bg-gray-200 cursor-pointer border-b"
                    onClick={() => handleCancelClick(name)} // Add onClick event handler to cancel the name
                  >
                    {name} <span className="text-red-500">&times;</span>
                  </li>
                ))}
              </ul>
            </div>
          )}
        </div>
        <div className="flex gap-4 text-white pt-2">
          <button
            onClick={handleSave}
            className="flex-1 bg-green-500 hover:bg-green-400 py-1 rounded"
          >
            Add
          </button>
          <button
            onClick={editEmployee}
            className="flex-1 bg-rose-500 hover:bg-rose-400 py-1 rounded"
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
};

export default EditEmployee;
