import React, { useState, useEffect } from 'react';
import AddSubtask from '../modals/AddSubtask';
import ReactPaginate from 'react-paginate';

const Tasks = () => {

    const [subtask, setSubtask] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [currentData, setCurrentData] = useState([]);
    const [data, setData] = useState([
        {
            id: 1,
            proNumber: 'c50cd421-341c',
            taskName: 'c1646s6dc462s',
            operatorName: 'Mark E. Romanowski',
            allocatedTime: '8 hours',
            startDate: '10/03/23',
            actualStartTime: '9:00 am',
            actualEndtTime: '5:00 pm',
            comments: 'lorem ipsum dolor sit amet consectetur adipisicing elit. Quisquam, voluptatum.',
            status: 'Active',
            efficiency: '70%',
            instructionFile: 'pdf',
        },
        {
            id: 2,
            proNumber: 'c50cd421-341c',
            taskName: 'c1646s6dc462s',
            operatorName: 'Mark E. Romanowski',
            allocatedTime: '8 hours',
            startDate: '10/03/23',
            actualStartTime: '9:00 am',
            actualEndtTime: '5:00 pm',
            comments: 'lorem ipsum dolor sit amet consectetur adipisicing elit. Quisquam, voluptatum.',
            status: 'Active',
            efficiency: '70%',
            instructionFile: 'pdf',
        },
        {
            id: 3,
            proNumber: 'c50cd421-341c',
            taskName: 'c1646s6dc462s',
            operatorName: 'Mark E. Romanowski',
            allocatedTime: '8 hours',
            startDate: '10/03/23',
            actualStartTime: '9:00 am',
            actualEndtTime: '5:00 pm',
            comments: 'lorem ipsum dolor sit amet consectetur adipisicing elit. Quisquam, voluptatum.',
            status: 'Active',
            efficiency: '70%',
            instructionFile: 'pdf',
        },
    ]);

    useEffect(() => {
        const itemsPerPage = 10;
        const indexOfLastItem = currentPage * itemsPerPage;
        const indexOfFirstItem = indexOfLastItem - itemsPerPage;
        setCurrentData(data.slice(indexOfFirstItem, indexOfLastItem));
    }, [currentPage, data]);

    const handlePageClick = ({selected}) => {
        setCurrentPage(selected + 1)
    };

    return (
        <div className='mt-4 p-4 rounded-md text-gray-700 bg-white shadow-md'>
            {subtask && <AddSubtask addSubtask={() => setSubtask(false)}/>}
            <div className="sm:flex justify-between items-center">
                <p className='text-xl font-semibold leading-10'>Jobs Management</p>
                <button onClick={() => setSubtask(true)} className='flex gap-2 items-center border px-4 py-1 text-white bg-indigo-600 hover:bg-indigo-500 rounded'>
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24"><path fill="currentColor" d="M12 4c4.411 0 8 3.589 8 8s-3.589 8-8 8s-8-3.589-8-8s3.589-8 8-8m0-2C6.477 2 2 6.477 2 12s4.477 10 10 10s10-4.477 10-10S17.523 2 12 2zm5 9h-4V7h-2v4H7v2h4v4h2v-4h4v-2z"/></svg>
                    Create subtask
                </button>
            </div>

            <div className="scroll-efffect-horizontal overflow-x-auto mt-4 text-[12px]">
                <div className="flex justify-between text-indigo-600 leading-6 py-2 border-x bg-gray-100 min-w-[1172px]">
                    {/* <p className='w-8'></p> */}
                    <p className='w-24'>RPO Number</p>
                    <p className='w-24'>Task Name</p>
                    <p className='w-32'>Operator Name</p>
                    <p className='w-20'>Allocated Time</p>
                    <p className='w-16'>Start Date</p>
                    <p className='w-24'>Actual Start Time</p>
                    <p className='w-24'>Actual End Time</p>
                    <p className='w-52'>Comments</p>
                    <p className='w-28'>Status</p>
                    <p className='w-16'>Efficiency</p>
                    <p className='w-20'>Instruction File</p>
                </div>
                <div className=" leading-9 min-w-[1172px]">
                {currentData?.map((item) => (
                    <div key={item.id} className="flex items-center border-x justify-between border-b border-gray-100 leading-6">
                        {/* <button className="w-8 py-2 flex">
                            <svg onClick={() => openView(item)} className='w-full m-auto fill-yellow-400 hover:fill-yellow-600'xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24"><path d="M12 9a3 3 0 0 0-3 3a3 3 0 0 0 3 3a3 3 0 0 0 3-3a3 3 0 0 0-3-3m0 8a5 5 0 0 1-5-5a5 5 0 0 1 5-5a5 5 0 0 1 5 5a5 5 0 0 1-5 5m0-12.5C7 4.5 2.73 7.61 1 12c1.73 4.39 6 7.5 11 7.5s9.27-3.11 11-7.5c-1.73-4.39-6-7.5-11-7.5Z"/></svg>
                        </button> */}
                        <p className='w-24'>{item.proNumber}</p>
                        <p className='w-24'>{item.taskName}</p>
                        <p className='w-32'>{item.operatorName}</p>
                        <p className='w-20'>{item.allocatedTime}</p>
                        <p className='w-16'>{item.startDate}</p>
                        <p className='w-24'>{item.actualStartTime}</p>
                        <p className='w-24'>{item.actualEndtTime}</p>
                        <span className="w-52">
                        {item.comments.length > 50 ? (
                            <div>
                                <p>{item.comments.slice(0, 40)} ... <span className="text-indigo-600 font-bold cursor-pointer">View More</span></p>
                            </div>
                        ) : (
                            <p>{item.comments}</p>
                        )}
                        </span>
                        <p className='w-28'>{item.status}</p>
                        <p className='w-16'>{item.efficiency}</p>
                        <p className='w-20'>{item.instructionFile}</p>
                    </div>
                ))}
                </div>
            </div>
            <ReactPaginate
                breakLabel={'...'}
                pageCount={Math.ceil((data?.length || 0) / 10)}
                pageRangeDisplayed={3}
                onPageChange={handlePageClick}
                previousLabel="Previous"
                nextLabel="Next"
                previousLinkClassName="px-2 py-px rounded-sm bg-blue-500 text-white"
                nextLinkClassName="px-2 py-px rounded-sm bg-blue-500 text-white"
                activeClassName="bg-blue-500 text-white rounded-sm"
                pageLinkClassName="p-2"
                className='flex gap-2 mt-5 justify-end'
            />
        </div>
    );
}

export default Tasks;
