import React, { useState, useEffect } from "react";
import ReactPaginate from "react-paginate";
import EditEmployee from "../modals/EditEmployee";
import AddEmployee from "../modals/AddEmployee";

const Employee = () => {
  const [addEmployee, setAddEmployee] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [currentData, setCurrentData] = useState([]);
  const [editEmployee, setEditEmployee] = useState(false);
  const [data, setData] = useState([]);
  const [id, setId] = useState(null);
  const [name, setName] = useState("");

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    const token = localStorage.getItem("token");

    try {
      const response = await fetch(
        "https://ampac.atpldhaka.com/api/employee-resource",
        {
          headers: {
            Accept: "application/json",
            Authorization: token.slice(token.indexOf("|") + 1),
          },
        }
      );
      const data = await response.json();
      setData(data.employees);
      // setLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      // setLoading(false);
    }
  };

  useEffect(() => {
    if (data) {
      const itemsPerPage = 10;
      const indexOfLastItem = currentPage * itemsPerPage;
      const indexOfFirstItem = indexOfLastItem - itemsPerPage;
      setCurrentData(data.slice(indexOfFirstItem, indexOfLastItem));
    }
  }, [currentPage, data]);

  const handleDelete = (id) => {
    const token = localStorage.getItem("token");
    fetch(`https://ampac.atpldhaka.com/api/employee-resource/${id}`, {
      method: "DELETE",
      headers: {
        Accept: "application/json",
        Authorization: token.slice(token.indexOf("|") + 1),
      },
    })
      .then((response) => response.json())
      .then((data) => {
        fetchData();
      })
      .catch((error) => {
        console.error("Error deleting data:", error);
      });
  };

  const handlePageClick = ({ selected }) => {
    setCurrentPage(selected + 1);
  };

  return (
    <div className="w-full p-4 text-gray-700 bg-white shadow-md rounded-md mt-4">
      {addEmployee && (
        <AddEmployee
          addEmployee={() => setAddEmployee(false)}
          fetchCall={() => fetchData()}
        />
      )}
      {editEmployee && (
        <EditEmployee
          editEmployee={() => setEditEmployee(false)}
          editId={id}
          fetchCall={() => fetchData()}
          name={name}
        />
      )}
      <div className="sm:flex space-y-2 sm:space-y-0 justify-between items-center">
        <p className="font-bold text-xl">Employee/Resource</p>
        <button
          onClick={() => setAddEmployee(true)}
          className="flex items-center gap-2 bg-indigo-600 hover:bg-indigo-500 px-4 py-1 rounded text-white"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            viewBox="0 0 24 24"
          >
            <path
              fill="currentColor"
              d="M12 4c4.411 0 8 3.589 8 8s-3.589 8-8 8s-8-3.589-8-8s3.589-8 8-8m0-2C6.477 2 2 6.477 2 12s4.477 10 10 10s10-4.477 10-10S17.523 2 12 2zm5 9h-4V7h-2v4H7v2h4v4h2v-4h4v-2z"
            />
          </svg>
          Add New Employee
        </button>
      </div>
      <div className="scroll-efffect-horizontal overflow-x-auto mt-4">
        <div className="flex justify-between border text-indigo-600 text-sm font-medium leading-10 bg-gray-200 min-w-[1156px] px-2">
          <p className="w-32">Employee Name</p>
          <p className="w-28">Username</p>
          <p className="w-44">Email</p>
          <p className="w-28">Mobile Number</p>
          <p className="w-24">Job Type</p>
          <p className="w-24">Qualification</p>
          <p className="w-32">Assign a Role</p>
          <p className="w-36">Reporting Employee</p>
          <p className="w-20">Status</p>
          <p className="w-20">Actions</p>
        </div>
        <div className="border-x text-sm leading-9 min-w-[1156px]">
          {currentData?.map((item, index) => (
            <div key={index} className="flex justify-between border-b px-2">
              <p className="w-32">{item.full_name}</p>
              <p className="w-28">{item.name}</p>
              <p className="w-44">{item.email}</p>
              <p className="w-28">{item.mobile_number}</p>
              <p className="w-24">{item.job_types}</p>
              <p className="w-24">{item.qualification}</p>
              <p className="w-32">{item.role_name}</p>
              <div className="w-36 flex items-center">
                {item.assignedUsers?.map((name, index) =>
                  index < 5 ? (
                    <div
                      key={index}
                      className={`h-7 w-7 rounded-full bg-teal-600 flex items-center border justify-center text-white shadow ${
                        index ? "-ml-2" : ""
                      }`}
                    >
                      {name.slice(0, 2).toUpperCase()}
                    </div>
                  ) : null
                )}
                {item.assignedUsers.length > 4 && (
                  <div
                    className={`h-7 w-7 rounded-full bg-teal-600 flex items-center border justify-center text-white shadow -ml-2`}
                  >
                    +{item.assignedUsers.length - 5}
                  </div>
                )}
              </div>
              <p className="w-20">{item.status}</p>
              <div className="flex w-20 gap-4 items-center">
                <svg
                  onClick={() => {
                    setEditEmployee(true);
                    setId(item.id);
                    setName(item.full_name);
                  }}
                  className="cursor-pointer"
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  viewBox="0 0 24 24"
                >
                  <path
                    fill="#0abb8f"
                    d="M3 17.25V21h3.75L17.81 9.94l-3.75-3.75L3 17.25zM21.41 6.34l-3.75-3.75l-2.53 2.54l3.75 3.75l2.53-2.54z"
                  />
                </svg>
                <svg
                  onClick={() => handleDelete(item.id)}
                  className="cursor-pointer"
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  viewBox="0 0 24 24"
                >
                  <path
                    fill="#e82d29"
                    d="M19 4h-3.5l-1-1h-5l-1 1H5v2h14M6 19a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V7H6v12Z"
                  />
                </svg>
              </div>
            </div>
          ))}
        </div>
      </div>
      <ReactPaginate
        breakLabel={"..."}
        pageCount={Math.ceil((data?.length || 0) / 10)}
        pageRangeDisplayed={3}
        onPageChange={handlePageClick}
        previousLabel="Previous"
        nextLabel="Next"
        previousLinkClassName="px-2 py-px rounded-sm bg-blue-500 text-white"
        nextLinkClassName="px-2 py-px rounded-sm bg-blue-500 text-white"
        activeClassName="bg-blue-500 text-white rounded-sm"
        pageLinkClassName="p-2"
        className="flex gap-2 mt-5 justify-end"
      />
    </div>
  );
};

export default Employee;
